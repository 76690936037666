import { documentReady } from './documentReady';

//
// Shows or hides a specified container
//
// Usage:
// <a href="javascript:"
//    data-js-content-switcher="class_name_of_target"
//    data-show-text="Show this stuff"
//    data-hide-text="Hide it again please">
//      ...
// </a>
//
var JsContentSwitcher = (function () {
  //"use strict";

  function JsContentSwitcher() {
    initialize();
  }

  // s = selectorMap:
  var s = {
    switches: '*[data-js-content-switcher]',
    target_data: 'js-content-switcher',
  };

  var stateMap = {
    showText: null,
    hideText: null,
  };

  var objMap = {
    $switch: null,
    $target: null,
  };

  var initialize = function () {
    $(document).on('click', s.switches, function () {
      var target_class_name = $(this).data(s.target_data);
      objMap.$target = $(`.${target_class_name}`);
      objMap.$switch = $(this);
      stateMap.showText = objMap.$switch.data('show-text') || 'Show';
      stateMap.hideText = objMap.$switch.data('hide-text') || 'Hide';

      showOrHide();
    });
  };

  var showOrHide = function () {
    if (objMap.$target.is(':visible')) {
      showIt(false);
    } else {
      showIt(true);
    }
  };

  var showIt = function (show_or_hide) {
    if (show_or_hide === true) {
      objMap.$target.slideDown(200);
      // visible now!
      objMap.$switch.text(stateMap.hideText);
    } else {
      objMap.$target.slideUp(200);
      // hidden now!
      objMap.$switch.text(stateMap.showText);
    }
  };

  return JsContentSwitcher;
})();

documentReady(function () {
  return new JsContentSwitcher();
});
