import { documentReady } from 'app/documentReady';

let hasExtendedJQuery = false;
const extendJQuery = () => {
  if (hasExtendedJQuery || !$) return;
  hasExtendedJQuery = true;

  $.extend({
    repositionate() {
      const placeholder = $('#upload_placeholder');
      const feature = $('#file_upload');
      if (placeholder.length > 0) {
        const new_position = placeholder.offset();
        feature.offset(new_position);
        feature.css('width', placeholder.width());
        return placeholder.css('height', feature.height());
      }
    },
  });
};

extendJQuery();
documentReady(function () {
  extendJQuery();
  if (!$('.users.edit').length) return;

  // repositionate upload logo upload feature
  $.repositionate();
  $(document).on('mouseup', '#page', () => $.repositionate());
  return $(window).resize(() => $.repositionate());
});
