import alertModal from './alertModal';
import Spinner from 'images/spinner_16x16.gif';
import { documentReady } from './documentReady';

const disableFileUploader = function (message = 'File Upload Disabled', selector = '#fileupload') {
  // NOTE: We must call with a "fresh selector", not a cached one..else `fileupload` thinks it's not yet initialized.
  $(selector).fileupload('disable');

  const input = $('input.multifile');
  // invisible input
  input.addClass('disabledbutton');
  input.attr('disabled', 'disabled');
  // visible button
  input.prev().attr('disabled', 'disabled');
  input.prev().text(message);
};

const fileUploadLimitReached = function () {
  const input = $('input.multifile');
  input.prev().attr('disabled', 'disabled');
  input.prev().text('Upload limit reached');
};

const fileUploadUnlimit = function () {
  const input = $('input.multifile');
  input.prev().removeAttr('disabled');
  input.prev().text('Upload a file...');
};

const enableFileUploader = function (selector = '#fileupload') {
  // NOTE: We must call with a "fresh selector", not a cached one..else `fileupload` thinks it's not yet initialized.
  $(selector).fileupload('enable');

  const input = $('input.multifile');
  // invisible input
  input.removeAttr('disabled');
  input.removeClass('disabledbutton');
  // visible button
  input.prev().removeAttr('disabled');
  input.prev().text('Upload a file...');
};

const showOrHideTeaser = function () {
  const no_attachments = $('.no-attachments');
  if ($('ul#attachments li a').length > 0) {
    no_attachments.hide();
  } else {
    no_attachments.show();
  }
};

const fileUploadSingleChange = function (input) {
  const selected_input = input.val().replace(/^.*[\/\\]/, ''); // eslint-disable-line no-useless-escape
  const selected_file = `<a><i class="ico ico-delete pull-right js-clear-file"></i><i class="ico ico-attachment"></i> ${selected_input}</a>`;
  if (selected_input !== '') {
    $('.no-attachments').hide();
    $('.js-filename').show();
    $('.js-filename').html(selected_file);

    const file = input[0].files[0];
    const max_file_size = 10 * 1024 * 1024 - 1024 * 5; // Max 10 MB, minus 5KB tolerance (See nginx conf)
    if (file.size > max_file_size) {
      input.val('');
      $('.js-filename').html(`
        <a><span class="text-error">File must be smaller than 10 MB. <br>
        Your file was ${(file.size / 1000 / 1000).toFixed(2)}MB</span></a>
      `);
    }
  } else {
    $('.no-attachments').show();
    $('.js-filename').hide();
  }
};

class InitFileUpload {
  constructor() {
    const selector = 'input#fileupload';
    const $input = $(selector);
    if (!$('div#pro_edit_user').length || !$input.length) return;

    $('input#fileupload').fileupload({
      url: '/user/update_pdf_logo',
      dataType: 'html',
      limitMultiFileUploads: 1,
      limitConcurrentUploads: 1,
      sequentialUploads: true,
      dropZone: $('#dropzone'),
      done: (e, data) => {
        const errors = data.result;
        if (errors.length > 1) {
          alert(errors);
          $('div.button-upload').removeClass('image-replace');
          $('div.button-upload').addClass('image-new');
          $('div#image_div').html('');
          return;
        }
        $('div.button-upload').removeClass('image-new');
        $('div.button-upload').addClass('image-replace');
        $('div#image_div').html('<img id="pdf_logo_image" />');
        $('img#pdf_logo_image').attr('src', `/user/download_pdf_logo?t=${new Date().getTime()}`);
      },
      start: () => {
        $('img#fileuploadspinner').show();
        $('div#image_div').html('');

        disableFileUploader('Uploading..', selector);
      },
      fail: () => {
        $('div#image_div').html(`<img src="${Spinner}" />`);
        $('div.button-upload').removeClass('image-replace');
        $('div.button-upload').addClass('image-new');
        alertModal('Please ensure that your file is not empty and less than 500 KB (kilobytes)');
      },
      always: () => {
        enableFileUploader(selector);
      },
    });
  }
}

class HoldingFileUpload {
  constructor() {
    const selector = 'input#holding_fileupload';
    const $input = $(selector);
    if (!$('ul#attachments').length && !$input.length) return;

    const holding_documents_limit = parseInt(
      $('div#holding_attachments_actions').data('holding-documents-limit')
    );
    const upload_url = $('div#holding_attachments_actions').data('upload-url');
    let errorShown = false;

    $input.fileupload({
      url: upload_url,
      dataType: 'html',
      limitMultiFileUploads: holding_documents_limit,
      limitConcurrentUploads: 1,
      sequentialUploads: true,
      dropZone: $('#dropzone'),
      done: (e, data) => {
        const error_split = data.result.split('_ERROR_/');
        if (error_split.length > 1) {
          if (!errorShown) {
            errorShown = true;
            alertModal(error_split[1]);
            return;
          }
        }
        $('ul#attachments').html(data.result);

        if ($('ul#attachments li').length >= holding_documents_limit) {
          disableFileUploader('Max File Uploads', selector);
          fileUploadLimitReached();
        }
      },
      start: () => {
        $('img#fileuploadspinner').show();
        errorShown = false;
        disableFileUploader('Uploading..', selector);
      },
      fail: (event, response) => {
        if (response?.jqXHR && response.jqXHR.status === 401) {
          errorShown = true;
          alertModal('Authentication error, please log in and try again.');
          window.location = '/';
        } else if (!errorShown) {
          errorShown = true;
          alertModal('Please ensure that your file is not empty and less than 10 MB (megabyte)');
          enableFileUploader(selector);
        }
      },
      always: () => {
        showOrHideTeaser();
        $('span#fileuploadprogress').hide();
        $('img#fileuploadspinner').hide();
        if ($('ul#attachments li').length < holding_documents_limit) {
          enableFileUploader(selector);
          fileUploadUnlimit();
        }
      },
      progress(e, data) {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        $('span#fileuploadprogress').show();
        $('span#fileuploadprogress').html(`${progress}% completed`);
      },
    });

    if ($('ul#attachments li').length >= holding_documents_limit) {
      disableFileUploader('Max File Uploads', selector);
      fileUploadLimitReached();
    }
  }
}

export function fileUploadAttachHandlers() {
  new InitFileUpload(); // pdf logo for pro
  new HoldingFileUpload(); // file upload on holding page

  showOrHideTeaser();

  $('input[class=singlefile]').bind({
    change() {
      const input = $(this);
      fileUploadSingleChange(input);
    },
    mouseenter() {
      $(this).prev().addClass('hover');
    },
    mouseover() {
      // no need
    },
    mousedown() {
      $(this).prev().addClass('active');
    },
    mouseup() {
      $(this).prev().removeClass('active');
    },
    mouseleave() {
      $(this).prev().removeClass('active hover');
    },
  });

  $('input[class=multifile]').bind({
    mouseenter() {
      $(this).prev().addClass('hover');
    },
    mousedown() {
      $(this).prev().addClass('active');
    },
    mouseup() {
      $(this).prev().removeClass('active');
    },
    mouseleave() {
      $(this).prev().removeClass('active hover');
    },
  });

  let countRemoveAttachment = 0;

  $(document).on('click', 'ul#attachments li .deletefile', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const image_delete = $(this);
    const spinner = $('img#fileuploadspinner');
    const url = image_delete.attr('data_url');

    if (!url) {
      // If there is no url to delete, don't DELETE the current path (this deletes the holding/trade).
      return false;
    }

    spinner.show();
    image_delete.css('position', 'relative').css('left', '-9999px');
    image_delete.next().css('opacity', '0.5');
    countRemoveAttachment++;
    $.post(url, { _method: 'delete' }, function () {
      image_delete.parent().remove();
      countRemoveAttachment--;
      if (countRemoveAttachment <= 0) {
        spinner.hide();
      }
      showOrHideTeaser();
      if ($('ul#attachments li a').length < 5) {
        fileUploadUnlimit();
      }
    });
  });

  $(document).on('click', '.attachments .ico.js-clear-file', function () {
    const input = $(this).closest('.attachments').find('input[type="file"]');
    input.val('');
    fileUploadSingleChange(input);
  });
}

documentReady(() => {
  fileUploadAttachHandlers();
});

window.fileUploadAttachHandlers = fileUploadAttachHandlers;
export default fileUploadAttachHandlers;
