import analytics from '@sharesight/react/dist/helpers/analytics';
import { documentReady } from './documentReady';

documentReady(() => {
  analytics.init();

  // Custom Load Events from jQuery can force re-initialization as a modal may have additional elements..
  // NOTE: This may create duplicate events unless you ensure it at the trigger level!
  if (typeof jQuery === 'function') {
    jQuery(document).on('main_updated', () => analytics.init(true));
    jQuery(document).on('overlay-ajax-loaded', () => analytics.init(true));
    jQuery(document).on('react-updated-dom', () => analytics.init(true));
  }
});
