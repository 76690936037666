import analytics from '@sharesight/react/dist/helpers/analytics';

import { documentReady } from './documentReady';

documentReady(function () {
  analytics.init();
  const autoSelect = $('input.auto_select_me');

  autoSelect.focus();
  autoSelect.select();

  $('#signup_form').on('submit', function () {
    analytics.track('sign_up__submission', {}, { only: 'mixpanel', anonymous: true });
  });
});
