import { documentReady } from './documentReady';

var AdvancedCriteriaBar = (function () {
  const selectorMap = {
    criteria_bar: 'div.criteria-bar ',
    criteria_bar_actions: '.criteria-bar-actions ',
    autocomplete_dropdown: '.ui-autocomplete ',
    adv_wrapper: 'div.advanced-options > .advanced-wrapper ',
    adv_container: 'div.advanced-options .advanced-container ',
    adv_actions: 'div.advanced-options .advanced-actions ',
    toggle_link: 'a.advanced-options-toggle-link ',
    close_link: 'a.advanced-options-close-link ',
  };
  const stateMap = {
    is_shown: false,
    height: null,
  };
  const configMap = {
    // Hides submit in criteria bar, stays it visible within the advanced section
    is_primary_submit_hidden: null,
    text_hide_options: 'Hide options',
    text_show_options: 'Advanced options',
  };

  let toggle = undefined;
  let initializer = undefined;
  let showOptions = undefined;
  let hideOptions = undefined;
  let getMaxWrapperHeight = undefined;

  AdvancedCriteriaBar = class AdvancedCriteriaBar {
    static initClass() {
      toggle = function () {
        if ($(selectorMap.adv_container).is(':animated')) return false;
        if (stateMap.is_shown === false) {
          return showOptions();
        } else {
          return hideOptions();
        }
      };

      initializer = function () {
        // set configMap
        configMap.is_primary_submit_hidden = $(selectorMap.criteria_bar).data(
          'config-primary-submit-hidden'
        );
        // set stateMap height after submit button was relocated if config 'is_primary_submit_hidden' is given
        if (configMap.is_primary_submit_hidden) {
          $(selectorMap.criteria_bar).find('[type=submit]').prependTo(selectorMap.adv_actions);
        }
        stateMap.height = $(selectorMap.adv_container).outerHeight();
        return hideOptions(true);
      };

      showOptions = function (immediate) {
        const $criteria_bar = $(selectorMap.criteria_bar);
        const $options = $(selectorMap.adv_container);
        const $wrapper = $(selectorMap.adv_wrapper);
        const $links = $(selectorMap.toggle_link);
        const duration = immediate != null ? 0 : 800;

        $options.removeClass('open');
        $wrapper.height(getMaxWrapperHeight());
        $options.addClass('open');

        // Animate SUBMIT
        if (configMap.is_primary_submit_hidden) {
          $criteria_bar.find('[type=submit]').show(200);
          $links.text(configMap.text_hide_options);
        } else {
          $criteria_bar.find('[type=submit]').hide(200, function () {
            $(this).prependTo(selectorMap.adv_actions).show(200);
            return $links.text(configMap.text_hide_options);
          });
        }

        // Animate advanced options
        $options.animate({ top: `+=${stateMap.height}` }, duration, 'easeOutQuint', function () {});

        return (stateMap.is_shown = true);
      };

      hideOptions = function (immediate) {
        const $options = $(selectorMap.adv_container);
        const $wrapper = $(selectorMap.adv_wrapper);
        const $links = $(selectorMap.toggle_link);
        const duration = immediate != null ? 0 : 300;

        $options.removeClass('open');

        // Animate SUBMIT
        if (configMap.is_primary_submit_hidden) {
          $options.find('[type=submit]').hide(200);
        } else {
          $options.find('[type=submit]').hide(200, function () {
            return $(this).prependTo(selectorMap.criteria_bar_actions).show(200);
          });
        }

        // Animate advanced options
        $options.animate({ top: `-=${stateMap.height}` }, duration, 'easeOutCirc', function () {
          $links.text(configMap.text_show_options);
          return $wrapper.height(0);
        });

        return (stateMap.is_shown = false);
      };

      getMaxWrapperHeight = function () {
        const doc_height = $(document).height();
        const adv_position_top = $(selectorMap.adv_wrapper).offset().top;
        return doc_height - adv_position_top;
      };
    }
    constructor() {
      if (!$(selectorMap.adv_wrapper).length) return false;

      // Set default statuses
      initializer();

      // Show or hide advanced options when clicking on link
      $(document).on('click', selectorMap.toggle_link, () => toggle());

      // Hide on outside click
      $(document).on('click', function (e) {
        if (!stateMap.is_shown) return true; // is already hidden
        if ($(e.target).closest(selectorMap.adv_container).length) return true; // click within advanced option content
        if (
          !(
            !$(e.target).closest(selectorMap.criteria_bar).length ||
            $(e.target)[0] === $(selectorMap.adv_wrapper)[0]
          )
        )
          return true; // click within criteria bar, but not in advanced options wrapper
        if ($(e.target).closest(selectorMap.autocomplete_dropdown).length) return true; // click within a ui-autocomplete dropdown
        return hideOptions();
      });

      // Close on close btn
      $(selectorMap.close_link).on('click', () => hideOptions());

      // Close on ESC key press
      $(document).keyup(function (e) {
        if (e.keyCode === 27) {
          return hideOptions();
        }
      }); // ESC key

      // Close on submit btn click
      $(document).on('click', `${selectorMap.adv_container}[type=submit]`, function () {
        hideOptions();
        return true;
      });
    }
  };

  AdvancedCriteriaBar.initClass();
  return AdvancedCriteriaBar;
})();

documentReady(() => {
  new AdvancedCriteriaBar();
});
