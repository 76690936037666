import { documentReady } from './documentReady';

documentReady(function () {
  // binding for update membership by ajax
  $(document).on('ajax:before', "form[data_type='update_membership']", function () {
    $(this)
      .find('a')
      .each(function () {
        $(this).html('Loading...');
        $(this).show();
      });
    $(this)
      .find('select')
      .each(function () {
        $(this).hide();
      });
  });
  $(document).on('ajax:complete', "form[data_type='update_membership']", function (data, status) {
    if (status.responseText == 'No Access' && $('table.table-dashboard').length > 0) {
      var table = $(this).parentsUntil('table').parent();
      $(this).parentsUntil('tr').parent().remove();
      if (table.find('tr').length < 2) {
        // nothing left under user to show...
        table.parentsUntil('tr').parent().prev().remove();
        table.parent().parent().remove();
      }
      return;
    }
    $(this)
      .find('a')
      .each(function () {
        $(this).html(status.responseText);
      });
  });
  $(document).on(
    'ajax:error',
    "form[data_type='update_membership'], #delete_membership",
    function (event, jqXHR) {
      if (jqXHR.status === 401) {
        window.location = '/';
      }
    }
  );
});
