import { documentReady } from './documentReady';

let hasExtendedJQuery = false;
const extendJQuery = () => {
  if (hasExtendedJQuery || !$) return;
  hasExtendedJQuery = true;

  $.extend({
    radio_btn_handling(item) {
      const $all_labels = item.parent('.radio-btn').parent().find('label.radio-btn');
      const $label = item.parent('label');

      $all_labels.removeClass('active');
      if (!$label.hasClass('active')) return $label.addClass('active');
    },
  });
};

extendJQuery();
documentReady(function () {
  extendJQuery();

  const $radio_btns_checked = $('.radio-btn input[type=radio]:checked');
  $.radio_btn_handling($radio_btns_checked);

  return $('.radio-btn input[type=radio]').on('click', function () {
    return $.radio_btn_handling($(this));
  });
});
