import { documentReady } from './documentReady';

class ChangeCard {
  constructor() {
    if (!$("a[data-invoke-change-card='true']").length) return;
    $(document).on('click', "a[data-invoke-change-card='true']", function () {
      $('div#existing_credit_card').hide();
      $('input#plan_form_payment_method_code').val('cc');
      $('#billing_details_form_title').html('Update Credit Card Details');
      $('div#new_credit_card').show();
    });
  }
}

class MethodSwitcher {
  constructor() {
    if (!$("span[data-payment-method-switcher='true']").length) return;

    $('button.use_ch').click(function () {
      const form = $(this).closest('form');
      $('input#plan_form_payment_method_code').val('ch');
      $(this).prop('disabled', true);
      $(this).html('Please wait...');

      form.submit();
    });

    if ($('span[data-payment-method-australian="true"]').length) {
      $('a.use_dd').click(function () {
        $('input#plan_form_payment_method_code').val('dd');
        $('#billing_details_form_title').html('Update Direct Debit Details');
        $(this).prop('disabled', true);
        $(this).html('Please wait...');
        $(this).closest('form').submit();
      });
    } else {
      $('a.use_dd').click(function () {
        $('input#plan_form_payment_method_code').val('dd');
        $(this).parent().hide();
        $('.use_cc').show();
        $('div#existing_credit_card, div#new_credit_card').hide();
        $('#billing_details_form_title').html('Update Direct Debit Details');
        $('div#dd_debit').show();
      });
    }

    $('a.use_cc').click(function () {
      $('input#plan_form_payment_method_code').val('cc');
      $(this).parent().hide();
      $('div#dd_debit').hide();
      $('.use_dd').show();
      $('div#new_credit_card').show();
      $('#billing_details_form_title').html('Update Credit Card Details');
      $('div.actions').show();
    });

    $('#plan-change-cc-confirm').click(function () {
      const form = $(this).closest('form');
      form.submit();
    });
  }
}

documentReady(function () {
  new ChangeCard(); // used on plan page, when decide to use not current stored credit card
  return new MethodSwitcher();
}); // switch payment method
