/**
 * This is "polyfill" for `$(document).ready(fn)` and `$(fn)` (which are the same, @see: https://learn.jquery.com/using-jquery-core/document-ready/)
 *
 * If jQuery is availbale, we use it.  Otherwise, use the modern way to load with vanilla javascript.
 */
export function documentReady(fn) {
  if (typeof $ === 'function') return $(fn);
  if (typeof jQuery === 'function') return jQuery(fn);

  // If the DOM has already loaded, fire this event in the next tick.
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    return setTimeout(fn, 1);
  }

  // If the DOM is still loading, await the event.
  return document.addEventListener('DOMContentLoaded', fn);
}
