import 'bootstrap'; // NOTE: Requires jQuery, which is shimmed in the pack that includes this file.
import { documentReady } from './documentReady';

documentReady(function () {
  $('body').tooltip({
    selector: '[rel=tooltip]',
    placement: function () {
      var placem = $(this)[0].$element.data('placement');
      if (placem !== null && placem !== '') {
        return placem;
      }
      return 'auto';
    },
  });

  $('[data-toggle=popover]').popover({
    trigger: 'hover',
    html: true,
    placement: function () {
      var placement = $(this)[0].$element.data('placement');
      switch (placement) {
        case null:
        case '':
          return 'auto';
        case 'responsive':
          if ($(window).width() < 420) {
            return 'auto';
          }
          return 'left';
        default:
          return placement;
      }
    },
  });

  $('[data-toggle=popover_focus]').popover({
    trigger: 'focus',
    html: true,
  });

  /*reminder for volume users*/

  var attachBehaviourToAjaxLink = function (data_type, during_callback, done_callback) {
    $(document).on('click', `a[data_type='${data_type}']`, function () {
      during_callback(this);
    });
    $(document).on('ajax:complete', `a[data_type='${data_type}']`, function () {
      done_callback(this);
    });
  };

  var changeLinkTextWhileLoading = function (data_type, during_label, done_label) {
    attachBehaviourToAjaxLink(
      data_type,
      function (el) {
        $(el).html(during_label);
      },
      function (el) {
        var parent = $(el).parent();
        $(el).remove();
        parent.html(done_label);
      }
    );
  };

  changeLinkTextWhileLoading('reminder', 'Sending...', 'Reminder sent');
  attachBehaviourToAjaxLink(
    'cancelFriend',
    function (el) {
      $(el).html('Cancelling...');
    },
    function (el) {
      var parent = $(el).parents('tr');
      parent.fadeOut();
    }
  );
});
