import { documentReady } from './documentReady';
import bugsnag from '@sharesight/react/dist/helpers/bugsnag';

class CreateForm {
  constructor() {
    this.handlePaymentTerms();
    this.updatePaymentMessages();

    $(document).on('click', 'input#recalculate', () => {
      this.recalculate();
    });

    $(document).on('keypress', 'input#plan_form_coupon_code', event => {
      if (
        !event.which &&
        (event.charCode || event.charCode === 0 ? event.charCode : event.keyCode)
      ) {
        event.which = event.charCode || event.keyCode;
      }
      if (event.which === 13) {
        event.preventDefault();
        this.recalculate();
      }
    });

    $(document).on('ajax:complete', () => {
      // For some reason rails ajax completion was killing the handler
      this.handlePaymentTerms();
      this.updatePaymentMessages();
    });
  }

  handlePaymentTerms() {
    if (
      !$('div#payment-terms').length &&
      !$('input#recalculate').length &&
      !$('input#plan_form_coupon_code').length
    )
      return;

    $('div#payment-terms').click(() => {
      this.updatePaymentMessages();
    });
  }

  updatePaymentMessages() {
    if ($('#plan_form_payment_term_1').prop('checked')) {
      $('div#yearlyMessage').hide();
      $('div#monthyMessage').show();
    } else {
      $('div#monthyMessage').hide();
      $('div#yearlyMessage').show();
    }
  }

  recalculate() {
    $('input#change_coupon').val($('input#plan_form_coupon_code').val());
    const payment_term = $('input[name="plan_form[payment_term]"]:checked').val();
    $('input#plan_form_payment_term').val(payment_term);

    $.ajax({
      url: `/plans`,
      type: 'post',
      data: $('input#change_coupon').parent().serialize(),
      success(data) {
        const js_coupon_error = $(data).find('span.js-coupon-error');
        if (js_coupon_error.length == 1) {
          const coupon_message = $('span.js-coupon-message');
          coupon_message.removeClass('dull success');
          coupon_message.addClass('error').text(js_coupon_error.text());
        } else {
          location.reload();
        }
      },
      error() {
        bugsnag.notify(new Error('Rate limit for re-calculating coupons reached.'), event => {
          event.addMetadata('sharesight', {
            contentType: 'application/json',
            url: location.href,
          });

          event.severity = 'info';
          event.context = 'plans.js';
        });

        alert(`Too many failed attempts. Try again in a few hours.`);
        location.reload();
      },
    });
  }
}

documentReady(function () {
  new CreateForm();
}); // plan, choose payment method js logic
