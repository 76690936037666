import { documentReady } from './documentReady';

var DowngradePlan = (function () {
  let toogle_actions = undefined;

  DowngradePlan = class DowngradePlan {
    static initClass() {
      toogle_actions = function () {
        const numOfSelectedPortfolios = $('ul').find('input.keep_portfolio:checked').length;

        if (
          numOfSelectedPortfolios <=
          $('form#form_downgrade_plan').data('allowed-number-of-portfolios')
        ) {
          $('input#confirm_button').each(function () {
            $(this).removeAttr('disabled');
            $(this).removeClass('disablebutton');
            $(this).addClass('medium_orange');
          });
          return;
        }

        $('input#confirm_button').each(function () {
          $(this).attr('disabled', 'true');
          $(this).removeClass('medium_orange');
          $(this).addClass('disablebutton');
        });
      };
    }

    constructor() {
      if (!$('form#form_downgrade_plan').length) return false;
      $(document).on('change', 'input.keep_portfolio', () => toogle_actions());
    }
  };

  DowngradePlan.initClass();
  return DowngradePlan;
})();

documentReady(() => {
  new DowngradePlan();
});
