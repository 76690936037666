import 'vendor/tablesorter';
import moment from '@sharesight/react/dist/helpers/moment';
import { number } from '@sharesight/react/dist/helpers/formatter';

import { documentReady } from './documentReady';

var Spreadsheet = {
  add_row_hovers: function () {
    var combinedSpans = $('table.clickable tbody tr span.combined');
    combinedSpans.on('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    });

    combinedSpans.find('a').on('click', function (e) {
      window.open($(this).attr('href'), '_self');
      e.stopPropagation();
      e.preventDefault();
      return false;
    });

    $(document).on(
      {
        click: function (e) {
          e.stopPropagation();

          const link = $(this).find('a').not('.non-clickable').first();
          const href = link.attr('href');
          const lightbox = !!link.data('lightbox-inline') || !!link.data('lightbox-ajax');
          const non_clickable = link.parent('td').hasClass('non-clickable');
          const regex_real_link = new RegExp(/^#|^javascript:|^$/);
          const isValidLink = !regex_real_link.test(href);

          if (isValidLink && !non_clickable && !lightbox) {
            // if valid link?
            e.preventDefault();
            window.open(href, '_self');
          } else if (lightbox && href && !isValidLink) {
            // if link is for an overlay or a fake link (# or javascript:)
            link.click();
          }
        },
        mouseover: function () {
          var tr = $(this);
          if (tr.find('a').length) tr.addClass('hover');
        },
        mouseout: function () {
          $(this).removeClass('hover');
        },
      },
      'table.clickable tbody tr:has(a), table.clickable tfoot.clickable tr:has(a)'
    );
  },
};

var Sortable = {
  current_th_sorted: null,
  setup_sorting: function () {
    $('table.sortable').each(function () {
      // options for sorting
      var options = {
        cssAsc: 'sortdesc',
        cssDesc: 'sortasc',
        widgetZebra: { css: ['roweven', 'rowodd'] },
        widgets: ['zebra'],
      }; // It's weird like that so the arrows point the right way.
      //don't sort on any columns with the class 'nosort'
      var nosort_columns = $(this)
        .find('th.nosort')
        .map(function () {
          return $(this).index();
        });

      if (nosort_columns.length > 0) {
        var headers = {};
        for (var i = 0; i < nosort_columns.length; i++) {
          headers[nosort_columns[i]] = { sorter: false };
        }
        options['headers'] = headers;
      }
      $(this).tablesorter(options);

      $(this)
        .find('th')
        .click(function (e) {
          if (!$(e.target).is('a, button')) {
            Sortable.current_th_sorted = $(this).parent().children().index(this);
          }
        });

      // enable_sort_headers
      $(this)
        .find('th')
        .each(function () {
          if (!$(this).hasClass('nosort')) {
            $(this).html(`<div class='arrow'><span class='text'>${$(this).html()}</span></div>`);
          }
        });
    });
  },
  get_matching_filter_number_based_on_display_as: function (s) {
    // We assume we're getting a value in here like `$1,234.12 1,234.12%`.
    const clean = s.split(' ').filter(split => split && split !== '\n');

    // If we're showing $$, return the second pair.
    if ($('.switch-to-dollar.active').length > 0 && clean[1]) return clean[1];

    // Either we don't have a $$ value or we're showing %.
    return clean[0];
  },
  rebuild_cache: function () {
    $('table.sortable').trigger('update');
    Sortable.reinit_current_th_click();
  },
  reinit_current_th_click: function () {
    if (Sortable.current_th_sorted !== null) {
      $($('table.sortable th')[Sortable.current_th_sorted]).click().click();
    }
  },
};

documentReady(function () {
  // TableSort parser for date format: 6 Jan 1978
  $.tablesorter.addParser({
    id: 'dayMonthYear',
    is: function () {
      return false;
    },
    format: function (str) {
      var date = moment(str, 'DD MMM YYYY');
      if (!str || !date.isValid()) date = moment().set('year', 1900); // just default to an old date (sort last)

      return date.format('YYYYMMDD');
    },
    type: 'numeric',
  });

  // TableSort parser for numbers in the format -1,234.56  1,234,567.89  etc
  $.tablesorter.addParser({
    id: 'prettyNumber',
    is: () => false, // don't auto-assign this parser at all
    format: function (input) {
      let string = String(input).replace(/\s+/g, ' ');
      if (!string.trim()) return 0;

      if ($('.switch-to-dollar').length > 0) {
        // If we can toggle between dollar and percentage, we need to grab one number or another..
        // Eg. this might be a string like `$1,234.12 1,234.12%` because we render both values to HTML and show/hide one.
        string = Sortable.get_matching_filter_number_based_on_display_as(string);
      }

      return number.from(string) || 0;
    },
    type: 'numeric',
  });

  if ($('.dollar-percent-switch').length === 0) {
    Sortable.setup_sorting();
  }

  Spreadsheet.add_row_hovers();

  /*volume user dashboard */
  $(document).on('click', 'table.table-dashboard.view-container tr.expandable td a', function (e) {
    // prevent from expanding row when clicking link
    window.location = $(this).attr('href');
    e.preventDefault();
    return false;
  });
  $(document).on('click', 'table.table-dashboard.view-container tr.expandable td', function () {
    var currentElem = $(this);
    var elem = currentElem.parent().next().children();
    elem.fadeToggle(function () {
      if (elem.is(':visible')) {
        $('table.table-dashboard thead tr th').find('span.shown_on_request').show();
        currentElem.parent().find("*[data-expand='expand']").hide();
        currentElem.parent().find("*[data-expand='collapse']").show();
        return;
      }
      currentElem.parent().find("*[data-expand='collapse']").hide();
      currentElem.parent().find("*[data-expand='expand']").show();
    });
  });
});

window.Sortable = Sortable;
