import { documentReady } from './documentReady';

class DisableFormFields {
  constructor() {
    if (!$("span[data-disable-input-textarea='true']").length) return;
    setTimeout(function () {
      $('input, textarea, select').prop('disabled', 'disabled');
    }, 300);
  }
}

// disable all trades / payouts fields for non editor
documentReady(() => {
  new DisableFormFields();
});
