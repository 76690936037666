import { documentReady } from './documentReady';
import GlobalVars from './variables';

//
// Counts GlobalVars upwards when a initialization should be remembered
// through several ajax loads.
//
// Requires: GlobalVars.initialized = {}
//
class InitializationReminder {
  constructor() {
    this.remember = this.remember.bind(this);
    return {
      was: this.was,
      is: this.was,
      remember: this.remember,
      reset: this.reset,
    };
  }

  // returns if this 'method_name' was already initialized or not
  was(name) {
    return !!GlobalVars.initialized[name];
  }

  // remembers a 'method_name' initialization and counts it upwards
  remember(name) {
    if (GlobalVars.initialized[`${name}`] == null) this.reset(name);
    return (GlobalVars.initialized[`${name}`] += 1);
  }

  // resets a 'method_name' initialization
  reset(name) {
    return (GlobalVars.initialized[`${name}`] = 0);
  }
}

documentReady(function () {
  window.iniReminder = new InitializationReminder();
});
