import { axios, jsonAxios } from '@sharesight/react/dist/helpers/axios';
import { number } from '@sharesight/react/dist/helpers/formatter';

import { initDatepickerSingle, formatInputDate } from './datepicker';
import { documentReady } from './documentReady';

const HoldingMergeForm = {
  init: function () {
    this.watchMergeDate();
    this.watchMergeQuantity();
    this.watchMergeCancelledPrice();
    this.watchDbPriceClicks();
    this.watchToggleManualPriceFieldgroup();

    this.triggerInstrumentPriceRender();

    this.initDatepicker();
  },

  dbPrice: null,
  dbPriceCurrency: null,

  initDatepicker: function () {
    const datepickers = $('.datepicker', this.getMergeFormElement());
    datepickers.each((index, datepicker) => {
      formatInputDate(datepicker);
      initDatepickerSingle.call(datepicker);
    });
  },

  watchDbPriceClicks: function () {
    const that = this;

    // against document, because it gets dynamically rendered
    $(document).on('click', '.js-holding-merge-cancelled-price', function () {
      const db_price = $(this).data().price;
      that.getCancelledPriceElement().val(db_price).trigger('change');
    });
  },

  watchMergeDate: function () {
    this.getMergeDateElement().change(() => {
      this.updateCostBase();
      this.triggerInstrumentPriceRender();
    });
  },

  watchMergeQuantity: function () {
    this.getMergeQuantityElement().on('change keyup', () => {
      this.updateAverageCostPerShare();
    });
    this.getMergeQuantityElement().on('focus', () => {
      this.updateFractionalMergeAllowed();
    });
  },

  watchMergeCancelledPrice: function () {
    this.getCancelledPriceElement().on('change keyup', () => {
      this.updateCancelledValue();
    });
  },

  watchToggleManualPriceFieldgroup: function () {
    const $link = $('.js-toggle-fieldgroup-manual-price');
    const $container = $('.js-fieldgroup-manual-price');
    $link.on('click', () => {
      if ($container.is(':visible')) {
        $link.text('edit');
        $container.hide();
      } else {
        $link.text('close');
        $container.show();
      }
    });
  },

  triggerInstrumentPriceRender: async function () {
    if (!this.getPriceElement().length) return;
    this.getPriceElement().html('&nbsp;');

    const { data } = await jsonAxios({
      cache: true,
      params: {
        id: this.getValueElement().data().mergeCancelledInstrumentId, // todo
        date: this.getMergeDateElement().val(),
      },
      url: '/instruments/price',
    });

    // Set HTML based on response.
    this.dbPrice = data.price;
    this.dbPriceCurrency = data.currency;
    this.setLinkHtml(data);

    // update cancelled value
    this.updateCancelledValue();
  },

  setLinkHtml: function (data) {
    if (!this.getPriceElement().length) return;

    let linksHtml = `<a href="javascript:" class="js-holding-merge-cancelled-price" data-price="${
      this.dbPrice
    }">
      ${number.to(this.dbPrice, { symbol: this.dbPriceCurrency })}
    </a>`;

    if (data.messages.length) {
      linksHtml += `<a class="icon_with_tooltip " rel="tooltip" href="javascript:" data-original-title="${data.messages[0]}">
        <i class="ico ico-tooltip " alt="what is this?"></i>
      </a>`;
    }

    this.getPriceElement().html(linksHtml);
  },

  updateCancelledValue: function () {
    if (!this.getCancelledPriceElement().length) return;

    const price = parseFloat(this.getCancelledPriceElement().val() || this.dbPrice);

    // Treat quantity as a float or integer based on a setting.
    let qty = parseFloat(this.getValueElement().data().mergeCancelledQuantity);
    if (!this.isDecimalAllowed()) qty = parseInt(qty);

    const currency = this.getValueElement().data().mergeCancelledCurrency;
    const value = price * qty || 0;

    $('.js-market-value').text(number.to(value, { symbol: currency }));
  },

  updateAverageCostPerShare: function () {
    // retrieve factors
    var costBase = $('#holding_merge_cost_base').html();

    // Treat quantity as a float or integer based on a setting.
    let qty = parseFloat(this.getMergeQuantityElement().val());
    if (!this.isDecimalAllowed()) qty = parseInt(qty);

    if (isNaN(qty) || qty <= 0) qty = 1;

    // calculate the value
    const averageCostPerShare = (costBase / qty).toFixed(2);

    // display the value
    $('#holding_merge_average_cost_per_share').html(averageCostPerShare);
  },

  updateFractionalMergeAllowed: function () {
    let step = 1;
    if (this.isDecimalAllowed()) step = 0.0001;
    this.getMergeQuantityElement().attr('step', step);
  },

  holdingId: function () {
    return this.getMergeFormElement().data('holding-id');
  },

  updateCostBase: async function () {
    const { data } = await axios({
      cache: true,
      params: {
        ignore_merge_cancel_transactions: true, // todo
        on: this.getMergeDateElement().val(),
      },
      url: `/holdings/${this.holdingId()}/trades/cost_base`,
    });

    $('span#holding_merge_cost_base').html(parseFloat(data).toFixed(2));
  },

  selectors: {}, // cache
  getMergeFormElement: function () {
    const name = 'form';
    if (this.selectors[name] && this.selectors[name].length) return this.selectors[name];
    return (this.selectors[name] = $('#js-holding-merge-form'));
  },

  getValueElement: function () {
    const name = 'value';
    if (this.selectors[name] && this.selectors[name].length) return this.selectors[name];
    return (this.selectors[name] = $('#partial_fields_for_value'));
  },

  getPriceElement: function () {
    const name = 'price';
    if (this.selectors[name] && this.selectors[name].length) return this.selectors[name];
    return (this.selectors[name] = this.getValueElement().find('.js-instrument-price'));
  },

  getCancelledPriceElement: function () {
    const name = 'cancelledPrice';
    if (this.selectors[name] && this.selectors[name].length) return this.selectors[name];
    return (this.selectors[name] = this.getValueElement().find('#holding_merge_cancelled_price'));
  },

  getMergeDateElement: function () {
    const name = 'date';
    if (this.selectors[name] && this.selectors[name].length) return this.selectors[name];
    return (this.selectors[name] = $('input#merge_merge_date'));
  },

  getMergeQuantityElement: function () {
    const name = 'quantity';
    if (this.selectors[name] && this.selectors[name].length) return this.selectors[name];
    return (this.selectors[name] = $('#holding_merge_quantity'));
  },

  getAllowDecimalElement: function () {
    const name = 'decimal';
    // Note: Do not cache in this.selectors[], because this react component re-renders its DOM
    return (this.selectors[name] = $(
      'input[name="holding_merge[instrument][search_selection_allow_decimal_quantity]"]'
    ));
  },

  isDecimalAllowed: function () {
    return !!(this.getAllowDecimalElement().val() === 'true');
  },
};

const initialize = () => {
  if (HoldingMergeForm.getMergeFormElement().length) HoldingMergeForm.init();
};

documentReady(() => {
  initialize();
  $(document).on('overlay-ajax-loaded', initialize);
});
