import { initDatepickerSingle, formatInputDate } from './datepicker';
import { documentReady } from './documentReady';

var Reports = {
  preparePriceRow: function (row_id) {
    const $row = $(`#${row_id}`);
    const $datepicker = $row.find('.last_traded_on');

    // give it a uniq id
    $datepicker.attr('id', `date_picker${new Date().getMilliseconds()}`);
    formatInputDate($datepicker[0]);
    initDatepickerSingle.call($datepicker);

    $row.find('.cancel').on('click', () => $row.remove());

    $row.find('[type=submit], .submit').on('click', function () {
      $(this).attr('disabled', true);

      $.ajax({
        data: `last_traded_on=${$row.find('.last_traded_on').val()}&last_traded_value=${$row
          .find('.last_traded_value')
          .val()}&row_id=${row_id}`,
        type: 'post',
        success: function (data) {
          // NOTE that 'success' can actually be validation errors here..
          $row.replaceWith(data);
          Reports.preparePriceRow(row_id);

          $(document).trigger('ad-hoc-price-added');
          return false;
        },
        error: function (request, text_status, error_thrown) {
          alert(`${text_status} ${error_thrown}`);
        },
        url: $('a[data-ad-hoc-instrument-prices-url]').data('ad-hoc-instrument-prices-url'),
      });
    });
  },

  refresh_balance: function (element) {
    var $checkbox = $(element);
    var $tr = $checkbox.closest('tr');
    if ($checkbox.is(':checked')) {
      $tr.addClass('selected');
    } else {
      $tr.removeClass('selected');
    }

    Reports.clear_balances();
    var url = `/portfolios/${$(
      'input[name=portfolio_id]'
    ).val()}/reports/fif/calculate_peak_balances`;
    $.ajax({
      data: $('#fif_form').serialize(),
      type: 'post',
      url: url,
      error: function () {
        Reports.reset_balance();
      },
    });
  },

  reset_balance: function () {
    $('.peak_balance_show_button').show();
    $('.peak_balance_show_pending').hide();
    $('.peak_balance_show_value').hide();
  },

  clear_balances: function () {
    $('#companies tbody tr.holding td.peak').html('&nbsp;');
    $('#companies tbody tr.holding td.trades_link').html('&nbsp;');

    $('.peak_balance_show_button').hide();
    $('.peak_balance_show_pending').show();
    $('.peak_balance_show_value').hide();

    $('#peak_date').html('');
  },

  edit_exchange_rates: function () {
    $('.default_exchange_rates').hide();
    $('.user_exchange_rates input').removeAttr('disabled');
    $('.user_exchange_rates').show();
  },

  cancel_exchange_rates: function () {
    $('.user_exchange_rates').hide();
    $('.default_exchange_rates').show();
    jQuery.each($('.user_exchange_rates input'), function (i, v) {
      $(v).val($(v).data('original'));
    });
    $('.user_exchange_rates input').attr('disabled', 'disabled');
  },
};

documentReady(function () {
  $('#js-fif-report-included-holdings').hide();
  $('#js-fif-report-included-holdings-toggle').click(function (e) {
    $('#js-fif-report-included-holdings').fadeToggle('slow');
    if ($(this).html() === 'show') {
      $(this).html('hide');
    } else {
      $(this).html('show');
    }
    e.preventDefault();
  });

  $('#peak_balance_calculate').click(Reports.refresh_balance);
});

window.Reports = Reports;
