// Visible recaptcha on app/views/devise/confirmations/show.html.erb
// Shown when the user was expected to activate immediately
window.onLoadReCAPTCHA = function () {
  if (typeof window.grecaptcha !== 'object') return;

  const container = document.getElementById('recaptcha_container');
  const submitButton = document.getElementById('recaptcha_submit');

  const key = container.dataset?.publicKey; // NOTE: dataset changes data attributes to camelCase!
  if (!key)
    throw new Error('ReCaptcha requires a Public Key to initialize, which we failed to find.');

  const btnLabel = submitButton.dataset.label || submitButton.value;

  const onSuccess = () => {
    submitButton.disabled = false;
    submitButton.value = btnLabel;
  };

  const onError = () => {
    submitButton.disabled = true;
    submitButton.value = "Confirm you aren't a robot";
  };

  window.grecaptcha.render(container, {
    callback: onSuccess,
    'error-callback': onError,
    'expired-callback': onError,
    sitekey: key,
    theme: 'light',
  });
};
