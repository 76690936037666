import Charts from './charts.js';
import { documentReady } from './documentReady.js';

documentReady(function () {
  $(document).on('overlay-ajax-loaded', '#modal_overlay', function () {
    if (!$('div#instrument_show').length || !$('#graph_container').length) return;
    return Charts.loadHighchartData(
      `${$('#graph_container').data('price-chart-data-url')}&min_tick_interval=4`,
      'instrument',
      'instrument_show #highchart_chart'
    );
  });
}); // bind load of chart in share checked overlay
