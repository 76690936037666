import { documentReady } from 'app/documentReady';

documentReady(function () {
  if (!$('input[name="share[portfolio]"]').length || !$('div#share_user').length) {
    return;
  }
  const share_portfolio_div = $('input[name="share[portfolio]"]');
  const user_share_div = $('div#share_user');

  return share_portfolio_div.click(function () {
    if ($(this).val() === 'share') {
      user_share_div.show();
    } else {
      user_share_div.hide();
    }
  });
});
