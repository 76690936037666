import { documentReady } from './documentReady';

class Comments {
  constructor() {
    if (!$('div#holding_comments button').length) return;
    $('div#holding_comments textarea').keydown(function () {
      $('div#holding_comments button').show();
    });
    $('form#comments_update')
      .bind('ajax:before', function () {
        $('div#holding_comments button').hide();
        $('div#holding_comments span#saving_comments').show();
      })
      .bind('ajax:complete', function () {
        $('div#holding_comments span#saving_comments').hide();
      })
      .bind('ajax:error', function (event, jqXHR) {
        if (jqXHR.status === 401) {
          window.location = '/';
        }
      });
  }
}

// comments on holding page
documentReady(() => {
  new Comments();
});
