import { documentReady } from './documentReady';

class ProfessionalSearch {
  constructor() {
    this.selectorMap = {
      input_professional_search: 'input[data-professional-search=true] ',
    };
  }

  ini() {
    const inputElement = $(this.selectorMap.input_professional_search);

    if (inputElement.length > 0) {
      return inputElement.keyup(function () {
        const start1 = new Date().getTime();

        // hide "No Results"
        const $noResults = $('.no-results');
        $noResults.hide();

        // create regular expression from user input
        const searchString = this.value.toUpperCase();

        const elapsed1 = new Date().getTime() - start1;
        $('#instrument_search_timing1').text(elapsed1);
        const start2 = new Date().getTime();

        $.each($('.expandable', '.table-dashboard'), function (i, elem) {
          const $elem = $(elem);

          // make sure all details are collapsed
          if (window.has_expands) {
            if ($elem.next().children(':first').is(':visible')) {
              const $details = $elem.next().children(':first');
              $details.hide(function () {
                if ($details.is(':visible')) {
                  $('table.table-dashboard thead tr th').find('span.shown_on_request').show();
                  $elem.find("*[data-expand='expand']").hide();
                  return $elem.find("*[data-expand='collapse']").show();
                } else {
                  $elem.find("*[data-expand='collapse']").hide();
                  return $elem.find("*[data-expand='expand']").show();
                }
              });
            }
          }

          // show only found items
          if ($elem.text().toUpperCase().indexOf(searchString) >= 0) {
            return $elem.show();
          } else {
            return $elem.hide();
          }
        });

        // show "No Results" if there are no results
        if ($('.expandable:visible', '.table-dashboard').length === 0) {
          $noResults.show();
        }
        window.has_expands = false;
        const elapsed2 = new Date().getTime() - start2;
        return $('#instrument_search_timing2').text(elapsed2);
      });
    }
  }
}

documentReady(function () {
  window.has_expands = false;
  $('.expand-button').click(() => (window.has_expands = true));

  const professionalSearch = new ProfessionalSearch();
  professionalSearch.ini();

  // Re-initialize when ajax content was loaded
  return $(document).on('switcher-ajax-loaded', () => professionalSearch.ini());
});
