import { documentReady } from './documentReady';

const SyncingCheck = {
  timer: null,
  data_url: null,

  init_load_check() {
    if (!($('*[data-delayed="true"]').length > 0)) return;
    this.data_url = $('*[data-delay-syncing-url]').data('delay-syncing-url');
    if (typeof this.data_url === 'undefined') return;
    return (this.timer = setInterval(this.check_status, 5000));
  },

  check_status() {
    return $.ajax({
      url: SyncingCheck.data_url,
      success(data) {
        if (data !== 'ok') return; // empty string, still running
        window.location.reload();
        return clearInterval(SyncingCheck.timer);
      },
    });
  },
};

documentReady(() => {
  SyncingCheck.init_load_check();
});
