/* WARNING: This is copied in `react/pdfs/jQuery/…` for use in PDFs specifically! */
import { number } from '@sharesight/react/dist/helpers/formatter';
import { documentReady } from './documentReady';

export const extendChartData = data => {
  // let's  not deep merge, just manually create defaults
  const chartData = Object.assign({}, data);
  if (!chartData.tooltip) chartData.tooltip = {};
  if (!chartData.chart) chartData.chart = {};
  if (!chartData.chart.events) chartData.chart.events = {};
  chartData.chart.events.load = () => {
    window.status = 'javascript_render_complete';
  };
  chartData.credits = { enabled: false }; // remove Highcharts.com watermark

  return chartData;
};

export const Charts = {
  highchartDataQueryCache: {},

  loadHighchartData(url, formatter, elementId = 'highchart_chart') {
    const chartSelector = `div#${elementId}`;
    const chartSpinnerSelector = `div#${elementId}_loading`;
    if (!window.jQuery(chartSelector).length) return;

    window.jQuery(chartSelector).hide();
    window.jQuery(chartSpinnerSelector).show();
    const benchmarkInstrumentId = window.jQuery('input#benchmark_instrument_id').val();
    const cacheKey = `${url}:${benchmarkInstrumentId}`;

    if (!this.highchartDataQueryCache[cacheKey]) {
      this.highchartDataQueryCache[cacheKey] = window.jQuery.ajax({
        url,
        data: { benchmark_instrument_id: benchmarkInstrumentId },
      });
    }

    return this.highchartDataQueryCache[cacheKey].done(res => {
      const chartData = extendChartData(res.graph);

      window.jQuery(chartSelector).show();
      window.jQuery('#graph_container').show();
      window.jQuery(chartSpinnerSelector).hide();

      chartData.tooltip.formatter = function chartTooltip() {
        return `<b>${this.point.y2}</b>, ${this.x}${this.point.name}`;
      };

      if (window.jQuery(window).width() < 600) {
        delete chartData.xAxis.labels.step;
        delete chartData.xAxis.labels.staggerLines;
      }

      Highcharts.chart(chartData);
    });
  },
};

documentReady(() => {
  if (window.jQuery('#diversity_chart').length && window.jQuery('span[data-chart-data]').length) {
    const currency = window.jQuery('#diversity_chart').data('portfolio-currency-symbol');

    let chartData = window.jQuery('span[data-chart-data]').data('chart-data');
    chartData = extendChartData(chartData);
    chartData.tooltip.formatter = function diversityChartTooltip() {
      return `${this.point.name}: <b>${number.to(this.point.y2, {
        decimal_places: 0,
        symbol: currency,
      })}</b>`;
    };

    Highcharts.chart(chartData);
  }
});

export default Charts;
