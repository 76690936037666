/* WARNING: This is copied in `react/pdfs/jQuery/…` for use in PDFs specifically! */
import { extendChartData } from './charts';
import { number, percent } from '@sharesight/react/dist/helpers/formatter';

function formatCurrency(n) {
  const portfolioCurrencyElement = document.querySelector('span#portfolio-currency-symbol');
  const portfolioCurrencySymbol = portfolioCurrencyElement
    ? portfolioCurrencyElement.innerText
    : '';

  return number.to(n, { precision: 0, symbol: portfolioCurrencySymbol });
}

export function contributionsChart(data, target) {
  const chartData = extendChartData(data);
  if (target) chartData.chart.renderTo = target.replace('#', '');

  // NOTE: This is specifically the Contributions Report Chart, but this is how we target that chart (unsure why, to refactor w/ Reactification).
  if (!document.querySelector('#chart_is_diversity')) {
    chartData.tooltip.formatter = function contributionChartFormatter() {
      const className = this.point.value_percent < 0 ? 'negative' : '';

      return `
        <strong>${this.point.category}</strong>:<br>
        <div class="${className}">${percent.to(this.point.value_percent, {
        decimal_places: 2,
      })}</div>
        <div class="${className}">${formatCurrency(this.point.value_money)}</div>
      `;
    };
  }

  return Highcharts.chart(chartData).yAxis[0].update({
    labels: {
      formatter() {
        return formatCurrency(this.value);
      },
    },
  });
}
