/* WARNING: This is copied in `react/pdfs/jQuery/…` for use in PDFs specifically! */
import { documentReady } from './documentReady';
import { extendChartData } from './charts';
import { contributionsChart } from './charts_drawer';

const selector = '.js-criteria-form-charting';

function renderChart(data, renderTo) {
  const target = window.jQuery(renderTo);
  if (!data?.chart) return target.hide();

  target.show();
  return contributionsChart(data, renderTo);
}

function showLoading(target, value) {
  if (!value || value === 'false') {
    return window.jQuery(target).hide();
  }

  window
    .jQuery(target)
    .addClass('js-criteria-form-charting-target')
    .show()
    .html(`<div class="chart-loading"><i class="ico ico-spinner ico-2x"></i></div>`);
}

function changeChartForSelect($ele) {
  const url = $ele.data('url');
  if (!url) throw new Error('No chart URL found');

  const target = $ele.data('target');
  const value = $ele.val();

  showLoading(target, value);

  return window.jQuery.get(url, { v: value }).then(data => {
    renderChart(extendChartData(data), target);
  });
}

function initialRender() {
  const $formSelect = window.jQuery(selector);

  let chartData = window.jQuery('span[data-chart-data]').data('chart-data');
  if (chartData) chartData = extendChartData(chartData);

  if (chartData?.chart && (!$formSelect.length || $formSelect.length === 1)) {
    const target = $formSelect.data('target');
    renderChart(chartData, target);
  }

  // Force the onChange for each formSelect.
  $formSelect.each(function forEachSelector() {
    changeChartForSelect(window.jQuery(this));
  });
}

function registerEvents() {
  // registering to the document as the select (selector) may not be immediate available / rendered
  // minorly less efficient, but this is jQuery anyways
  window.jQuery(document).on('change', selector, function onChangeSelector() {
    changeChartForSelect(window.jQuery(this));
  });
}

export default function initializeChart() {
  registerEvents();
  initialRender();
}

documentReady(() => {
  initializeChart();
});
