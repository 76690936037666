import { documentReady } from './documentReady';

documentReady(function () {
  $(document).on('click', '#myModal *[type=submit]', function () {
    $(`form[data_modal_id="${$(this).attr('data_modal_id')}"]`).submit();
  });

  $(document).on(
    'mousedown',
    '#myModal a[data-btn-confirm], #myModal a[data-method="delete"]',
    function (e) {
      // disable right click
      if (e.which !== 3) return;
      e.stopPropagation();
      e.preventDefault();
    }
  );

  $(document).on(
    'click',
    '#myModal a[data-btn-confirm], #myModal a[data-method="delete"]',
    function () {
      $(this).attr('disabled', true);
      $(this).closest('.modal').modal('hide');
      if ($(this).data('reload-on-confirm')) {
        setTimeout(() => window.location.reload(), 200);
      }
    }
  );

  $(document).on(
    'click',
    '#myModal a[data-btn-confirm], #myModal a[data-method="post"]',
    function () {
      const elem = $(`a#${$(this).attr('modal_element_id')}`);
      elem.parent().append('<b>Please Wait...</b>');
      elem.remove();
    }
  );
});
