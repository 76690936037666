import Tick from 'images/tick_small.gif';
import Spinner from 'images/spinner_16x16.gif';
import { documentReady } from './documentReady';

var XigniteLoading = (function () {
  const selectorMap = {
    loadingContainer: 'div[data-check-xignite-load]',
    dataUrl: 'check-xignite-load-status-url',
  };
  const imagesMap = {
    tick_small: `<img src="${Tick}" />`,
    spinner: `<img src="${Spinner}" />`,
  };
  const statusMap = { url: '' };

  let checkStatus = undefined;
  let checkPrices = undefined;

  XigniteLoading = class XigniteLoading {
    static initClass() {
      checkStatus = () => setTimeout(checkPrices, 3500);

      checkPrices = function () {
        if (!$(selectorMap.loadingContainer).length) return false;
        return $.getJSON(statusMap.url, function (data) {
          if (data.ready) {
            if (data.ready_action === 'reload') {
              window.location.reload();
            }
            if (data.ready_action === 'share_checker') {
              return $('form#new_search').submit();
            }
          } else {
            if (data.data_error) {
              $('#data_error').html(data.data_error);
            }
            if (data.quotes_loaded) {
              $('#quotes_loaded_value').html(
                `All loaded. (${data.number_quotes_loaded}) ${imagesMap.tick_small}`
              );
            } else {
              $('#quotes_loaded_value').html(
                `${data.number_quotes_loaded} loaded. ${imagesMap.spinner}`
              );
            }
            if (data.dividends_loaded) {
              $('#dividends_loaded_value').html(
                `All loaded. (${data.number_dividends_loaded}) ${imagesMap.tick_small}`
              );
            } else {
              $('#dividends_loaded_value').html(
                `${data.number_dividends_loaded} loaded. ${imagesMap.spinner}`
              );
            }
            if (data.splits_loaded) {
              $('#splits_loaded_value').html(
                `All loaded. (${data.number_splits_loaded}) ${imagesMap.tick_small}`
              );
            } else {
              $('#splits_loaded_value').html(
                `${data.number_splits_loaded} loaded. ${imagesMap.spinner}`
              );
            }
            return checkStatus();
          }
        });
      };
    }

    constructor() {
      if (!$(selectorMap.loadingContainer).length) return false;
      statusMap.url = $(selectorMap.loadingContainer).data(selectorMap.dataUrl);
      checkStatus();
    }
  };

  XigniteLoading.initClass();
  return XigniteLoading;
})();

documentReady(function () {
  new XigniteLoading();

  return $(document).on('overlay-ajax-loaded', () => new XigniteLoading());
});
