import { documentReady } from './documentReady';

documentReady(function () {
  $('.collapsible > .body').hide();

  $(document).on('click', '.collapsible .heading', function () {
    const $ele = $(this).parent();

    if ($ele.hasClass('shown')) {
      $ele.removeClass('shown');
      $ele.find('> .body').slideUp(300);
    } else {
      $ele.addClass('shown');
      $ele.find('> .body').slideDown(300);
    }
  });
});
