const errorHandler = jqXHR => {
  if (jqXHR && jqXHR.status === 401) window.location = '/';
};

const InstrumentPrice = {
  price_query_cache: {},

  load_instrument_price_by_id: function (id, on) {
    const priceLoadingElement = $('#price_loading_indicator');
    const marketPriceLoadingElement = $('#market_price_loading_indicator');
    const instrumentPriceElement = $('#instrument_price');
    const marketPriceElement = $('#market_price');

    const cacheKey = `${id}:${on}`;

    // if not cached, show loading elements before xhr
    if (!this.price_query_cache[cacheKey]) {
      priceLoadingElement.show();
      marketPriceLoadingElement.show();
      this.price_query_cache[cacheKey] = $.get(`/instruments/price?id=${id}&date=${on}`);
    }

    return this.price_query_cache[cacheKey]
      .done(data => {
        priceLoadingElement.hide();
        marketPriceLoadingElement.hide();

        instrumentPriceElement.html(data);
        instrumentPriceElement.show();

        if (marketPriceElement.length) {
          const hasAnchorMatch = data.match(/<a.*>(.*)<\/a>/);
          if (hasAnchorMatch !== null) $('#holding_trade_market_price').val(hasAnchorMatch[1]);

          marketPriceElement.html(data);
          marketPriceElement.show();
          marketPriceElement.trigger('change');
        }
      })
      .fail(errorHandler);
  },

  load_drp_instrument_price_by_id: function (id, on) {
    const drpPriceElement = $('#div#drp_price');

    const cacheKey = `${id}:${on}:drp`;

    // if not cached, hide before xhr
    if (!this.price_query_cache[cacheKey]) {
      drpPriceElement.hide();
      this.price_query_cache[cacheKey] = $.get(
        `/instruments/price?id=${id}&date=${on}&for_drp=true`
      );
    }

    return this.price_query_cache[cacheKey]
      .done(data => {
        drpPriceElement.html(data);
        drpPriceElement.show();
      })
      .fail(errorHandler);
  },
};

export default InstrumentPrice;
