import { documentReady } from './documentReady';

documentReady(function () {
  $(document).on('ajax:before', 'form.header_form', function () {
    return $(this).find('*[type="submit"]').prop('disabled', true).html('Please wait...');
  });

  return $(document).on('ajax:complete', 'form.header_form', function (data, status) {
    let match_elem = $(this).parent('div.header_form_content');
    if (match_elem.length === 0) {
      match_elem = $(this).parentsUntil('.header_form_content').last().parent();
    }

    match_elem.parent().html(status.responseText);

    if (
      $(this).data('reload-after-save') &&
      !status.responseText.match('Sorry, we have found (an error|some errors):')
    ) {
      return window.location.reload();
    }
  });
});
