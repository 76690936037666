import moment from '@sharesight/react/dist/helpers/moment';
import { documentReady } from './documentReady.js';

import GlobalVars from './variables.js';

const isoDateFormat = 'YYYY-MM-DD'; // for consistent parsing!
const getDateFormat = () => GlobalVars.user_preferences_date_format || 'DD/MM/YYYY';
const getPrintFormat = () => 'DD MMM YYYY';

let dateRanges = { Today: [moment(), moment()] }; // mutable

const baseOptions = element => {
  const $element = $(element);
  const options = {
    autoApply: true,
    showDropdowns: true,
    inkedCalendars: true, // This results in a bunch of NaN in some cases; broken in daterangepicker repo.  Just don't try it on v2.1.25
    minDate: GlobalVars.portfolioInceptionDate
      ? moment(GlobalVars.portfolioInceptionDate, 'YYYY-MM-DD').subtract(50, 'years') // you can create new trades really far into the past
      : moment().subtract(50, 'years'),
    maxDate: moment().add(3, 'years'),
    locale: {
      format: getDateFormat(),
      applyLabel: 'Set',
      cancelLabel: 'Cancel',
    },
  };

  // sets min and max date from html5 data
  ['start', 'end', 'min', 'max'].forEach(str => {
    const data = $element.data(`${str}-date`);
    if (data) {
      const date = data && moment(data, 'YYYY-MM-DD');
      if (date.isValid()) options[`${str}Date`] = date;
    }
  });

  // restricts minDate to avoid logic issues on app/models/trade.rb for dates must be >= 1950/1/1
  if (options.minDate.isBefore(moment('1950-01-01', 'YYYY-MM-DD'))) {
    options.minDate = moment('1950-01-01', 'YYYY-MM-DD');
  }

  return options;
};

const multipleDatePickerOptions = element =>
  $.extend(true, {}, baseOptions(element), {
    singleDatePicker: false,
    ranges: dateRanges, // this comes from the local dateRanges mutable object
  });

const singleDatePickerOptions = element =>
  $.extend(true, {}, baseOptions(element), {
    singleDatePicker: true,
  });

// NOTE: Order here dictates the order in daterangepicker element.
const fillDateRanges = function () {
  // date ranged for all reports that need past dates
  if (!$('div.datepicker-fields[data-future=false]').length) return;
  if (dateRanges['Yesterday'] != null) return; // fillDateRanges has already been ran
  if (GlobalVars.portfolioInceptionDate == null) return;
  const portfolioInceptionDate = moment(GlobalVars.portfolioInceptionDate);

  const yesterday = moment().subtract(1, 'days');
  if (portfolioInceptionDate.isBefore(yesterday)) {
    dateRanges['Yesterday'] = [yesterday, yesterday];
  }

  const seven_days_ago = moment().subtract(6, 'days');
  if (portfolioInceptionDate.isBefore(seven_days_ago)) {
    dateRanges['Last 7 Days'] = [seven_days_ago, moment()];
  }

  const beginning_of_month = moment().startOf('month');
  if (portfolioInceptionDate.isBefore(beginning_of_month)) {
    dateRanges['This Month'] = [beginning_of_month, moment().endOf('month')];
  }

  const last_month = moment().subtract(1, 'month').startOf('month');
  if (portfolioInceptionDate.isBefore(last_month)) {
    dateRanges['Last Month'] = [last_month, moment().subtract(1, 'month').endOf('month')];
  }

  const last_year = moment().subtract(1, 'year').add(1, 'day');
  if (portfolioInceptionDate.isBefore(last_year)) {
    dateRanges['Last Year'] = [last_year, moment()];
  }

  const last_ten_years = moment().subtract(10, 'years').add(1, 'day');
  if (portfolioInceptionDate.isBefore(last_ten_years)) {
    dateRanges['Last 10 Years'] = [last_ten_years, moment()];
  }

  dateRanges['Since Inception'] = [portfolioInceptionDate, moment()];

  addFinancialYearToOptions();
};

const fillFutureDateRanges = function () {
  // date ranged for all reports that need future dates
  if (!$('div.datepicker-fields[data-future=true]').length) return;
  if (dateRanges['Next 7 Days'] != null) return;
  dateRanges['Next 7 Days'] = [moment(), moment().add(7, 'days')];
  dateRanges['Next Month'] = [moment(), moment().add(1, 'month')];
  return (dateRanges['Next Year'] = [moment(), moment().add(1, 'year')]);
};

export function formatInputDates(parent = document) {
  const inputs = parent.querySelectorAll('input.datepicker');

  for (let i = 0; i < inputs.length; ++i) {
    let element = inputs[i];
    formatInputDate(element);
  }
}

export function formatInputDate(element) {
  if (!element) return;
  if (element.jquery) element = element[0]; // convert jQuery to DOM

  // we cache the moment in `data-cached-date` for subsequent runs; for the first run, parse from d/m/y or y-m-d
  // NOTE: Changes to the input value WILL NOT be reflected here.  Currently we do not rely on this date being modified however.
  const date_moment = element.getAttribute('data-cached-date')
    ? moment(element.getAttribute('data-cached-date'))
    : moment(element.value, ['DD/MM/YYYY', 'YYYY-MM-DD']);

  if (element.getAttribute('data-cached-date') && !date_moment.isValid()) {
    throw new Error(
      `Invalid cached date on a datepicker: '${element.value}' on ${
        element.getAttribute('name') || element.getAttribute('id')
      }`
    );
  } else if (element.value && !date_moment.isValid()) {
    throw new Error(
      `Invalid initial date on a datepicker: '${element.value}' on ${
        element.getAttribute('name') || element.getAttribute('id')
      }`
    );
  }

  if (date_moment.isValid()) {
    element.setAttribute('data-cached-date', date_moment.format());
    element.value = date_moment.format(getDateFormat()); // update the visible value, if it's a valid date
  }
}

const fillDates = function () {
  formatInputDates();
  fillDateRanges();
  fillFutureDateRanges();
};

var addFinancialYearToOptions = function () {
  if (dateRanges['Last Financial Year'] != null) return;
  if (
    typeof GlobalVars !== 'undefined' &&
    GlobalVars !== null &&
    GlobalVars.portfolioFinancialMonth != null
  ) {
    const financialEndMonth = GlobalVars.portfolioFinancialMonth - 1; // month is javascript starts from 0 - YO MAN!
    const financialEndDay = GlobalVars.portfolioFinancialDay - 1;
    let currentEndDate = moment()
      .startOf('year')
      .add(financialEndMonth, 'month')
      .add(financialEndDay, 'day');
    if (currentEndDate.isBefore(moment())) {
      currentEndDate = currentEndDate.add(1, 'year');
    }
    const currentStartDate = currentEndDate.clone().subtract(1, 'year').add(1, 'day');

    let previousStartDate = currentStartDate.clone().subtract(1, 'year');
    let previousEndDate = currentEndDate.clone().subtract(1, 'year');

    dateRanges['Last Financial Year'] = [previousStartDate, previousEndDate];
    dateRanges['Current Financial Year'] = [currentStartDate, currentEndDate];
  }
};

const setDates = function (element, start, end) {
  element.find('span').html(`${start.format(getPrintFormat())} - ${end.format(getPrintFormat())}`);

  const $startDateInput = $('input[type="hidden"][data-datepicker="start-date"]');
  const $endDateInput = $('input[type="hidden"][data-datepicker="end-date"]');

  if (!($startDateInput instanceof jQuery && $endDateInput instanceof jQuery)) {
    throw new Error('Found multiple start and end date inputs from daterangepicker.');
  }

  $startDateInput.val(start.format(isoDateFormat));
  $endDateInput.val(end.format(isoDateFormat));
};

export function initDatePickers() {
  fillDates();
  const $dp_fields = $('body:not(.admin) div.datepicker-fields');

  $dp_fields.each(initDatepickerRange);

  $dp_fields.on('apply.daterangepicker', function (e, picker) {
    if (picker.chosenLabel === 'Today' && e.type === 'apply') {
      setDates(picker.element, moment(), moment());
    }
  });

  $dp_fields.add('body:not(.admin) .datepicker').on('apply.daterangepicker', function () {
    // give single datepickers auto-submit to make them submit their form on change
    if ($(this).hasClass('auto-submit-datepicker')) {
      $(this).parents('form:first').submit();
    }
  });

  // Activate single datepickers, but only on focus.
  $(document).on(
    'focus',
    "body:not(.admin) input.datepicker[data-single-date=true]:not([disabled='disabled'])",
    initDatepickerSingle
  );
}

export function initDatepickerRange() {
  // needs this
  const $dp_field = $(this);
  const options = multipleDatePickerOptions($dp_field);

  $dp_field.daterangepicker(options, function (start, end) {
    setDates(this.element, start, end);
  });
}

export function initDatepickerSingle() {
  // needs this
  const $this = $(this);
  const options = singleDatePickerOptions($this);

  $this.daterangepicker(options);

  // Proxy change event from the dynamic datepicker element to the static dom element
  // Memoise date to prevent double triggering
  const initialValue = $this.val();
  return $this.one('change', ev => {
    if (ev.target.value !== initialValue) {
      return $this.trigger('changeDate');
    }
  });
}

documentReady(() => {
  initDatePickers();
});

window.initDatePickers = initDatePickers;
export default initDatePickers;
