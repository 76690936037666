import { documentReady } from './documentReady';

class InvitationLink {
  static initClass() {
    this.prototype.selection = null;
  }

  constructor(container) {
    this.container = container;
    if (InvitationLink.selection == null) return false;
    const link = InvitationLink.selection.data('invitation-link');
    const email = InvitationLink.selection.data('invitation-link-email');
    const name = InvitationLink.selection.data('invitation-link-name');
    this.draw_content(link, email, name);
  }

  draw_content(link, email, name) {
    const desc = `
      <h1 class='modal-title'>Invitation link <small>for ${email}</small></h1>
      <br/>
      <p>Please share the following invitation link with <em>${name}</em>, so that they can create a free guest account
      to view your portfolio.</p>
      <div class='box box-info text-center'>
        ${link}
      </div>
      <p class='text-error text-center'>
        Please copy the link above and paste it into your email for <em>${name}</em>.
      </p>
    `;
    $(this.container).html(desc);
  }
}

documentReady(function () {
  InvitationLink.initClass();

  $(document).on('click', '[data-invitation-link]', function () {
    InvitationLink.selection = $(this);
    $.trigger_overlay_dom_replacement($('#invitation_link_container')[0]);
  });

  $('#modal_overlay').on('overlay-ajax-loaded', function () {
    if ($('[data-invitation-link]').length > 0) new InvitationLink($('#invitation_link_container'));
  });
});
