/* 
  When a new user is signing up to Sharesight, this script will render a postal code input field for specific a specific tax residency
  selected by a user from the dropdown menu.
  Current tax residencies that require a postal code are: Canada(54) and United States(49). (Gabe: Jan, 8, 2024)
*/

import { documentReady } from './documentReady';

const taxResidencyCodesPlaceholderMap = {
  49: '100006', // United States
  54: 'M5X1J2', // Canada
};
const taxResidencyCodes = Object.keys(taxResidencyCodesPlaceholderMap);

const togglePostcodeField = selectedTaxResidencyCode => {
  const postalCodeField = $('#registration_required_post_code');
  const postalCodeInput = $(postalCodeField).children('input');

  if (taxResidencyCodes.includes(selectedTaxResidencyCode)) {
    const placeholderText = `e.g. ${taxResidencyCodesPlaceholderMap[selectedTaxResidencyCode]}`;
    postalCodeField.removeClass('hide');
    postalCodeInput.prop('required', true);
    postalCodeInput.prop('placeholder', placeholderText);
  } else {
    postalCodeField.addClass('hide');
    postalCodeInput.prop('required', false);
  }
};

documentReady(() => {
  const initialSelectedTaxResidencyCode = $('#tax_residency_dropdown_select').val();
  togglePostcodeField(initialSelectedTaxResidencyCode);

  $('#tax_residency_dropdown_select').on('change', function () {
    const selectedTaxResidencyCode = $(this).val();
    togglePostcodeField(selectedTaxResidencyCode);
  });
});
