import XeroSvg from 'images/xero.svg';
import XeroRunningSvg from 'images/xero_running.svg';
import XeroFailedSvg from 'images/xero_failed.svg';

import { documentReady } from './documentReady';

var Xero = {
  timer: null,
  TEMPLATES: {
    DONE: `<a title="This trade has been sent to Xero" class="tip" onclick="return false;" href="#"><img src="${XeroSvg}" alt="Xero" width="16" height="16"></a>`,
    RUNNING: `<a title="Synchronisation still running" class="tip" onclick="return false;" href="#"><img class="running" src="${XeroRunningSvg}" alt="Xero running" width="16" height="16"></a>`,
    FAILED: `<a title="Sync failed: #ERROR#" class="tip" target="" onclick="return false;" href="#"><img src="${XeroFailedSvg}" alt="Xero failed" width="16" height="16"></a>`,
  },

  updateIconsByHolding: async function (holding_id) {
    const data = await $.ajax({
      url: `/xero_messages/report/${holding_id}`,
      dataType: 'json',
    });

    let in_progress = false;
    $.each(data, function (i, data) {
      const span = $(`#xero-icon-${data.id}`);
      span.html(Xero.inflateIcon(data));

      if (data['running?']) in_progress = true;
    });

    if (in_progress) {
      // If the server says it's still running, wait 5000ms and run this function again.
      setTimeout(() => {
        Xero.updateIconsByHolding(holding_id);
      }, 5000);
    }
  },

  inflateIcon: function (message) {
    if (message['failed?']) {
      var error = 'Unknown reason';
      if (message.error_message) {
        error = message.error_message;
      }
      return Xero.TEMPLATES.FAILED.replace('#ERROR#', error);
    } else if (message['running?']) {
      return Xero.TEMPLATES.RUNNING;
    } else {
      return Xero.TEMPLATES.DONE;
    }
  },

  startUpdatingIcons: function () {
    Xero.timer = setInterval(Xero.updateIcons, 5000);
  },

  stopUpdatingIcons: function () {
    clearInterval(Xero.timer);
  },

  updateIcons: function () {
    var running = $('.js-xero-icons img.running');
    if (running.length > 0) {
      running.each(function () {
        var span = $(this).parent().parent();
        var message_id = span.data('message-id');

        $.ajax({
          url: `/xero_messages/${message_id}`,
          dataType: 'html',
          success: function (data) {
            span.html(data);
            span.find('a.icon_with_tooltip').tooltip();
          },
        });
      });
    } else {
      Xero.stopUpdatingIcons();
    }
  },

  updateInvoiceOptions: function (withEffect) {
    if ($('#xero_setting_sync_model_invoice').is(':checked')) {
      if (withEffect) {
        $('#js-xero-invoice-options').effect('highlight', {}, 1000);
      } else {
        $('#js-xero-invoice-options').show();
      }
      $('span#dividend_date_chooser').show();
      $('.js-xero-bank-transaction-options').hide();
    } else {
      $('span#dividend_date_chooser').hide();
      $('#js-xero-invoice-options').hide();
      if (withEffect) {
        $('.js-xero-bank-transaction-options').effect('highlight', {}, 1000);
      } else {
        $('.js-xero-bank-transaction-options').show();
      }
    }
  },

  watchInvoiceOptions: function () {
    $("input[name='xero_setting[sync_model]']").change(function () {
      Xero.updateInvoiceOptions(true);
    });
  },

  updateBrokerageAccount: function (withEffect) {
    if ($('#xero_setting_include_brokerage_in_trades').is(':checked')) {
      $('#js-brokerage-account').hide();
    } else {
      if (withEffect) {
        // $('#js-brokerage-account').effect('highlight', {}, 1000);
        $('#js-brokerage-account').show();
      } else {
        $('#js-brokerage-account').show();
      }
    }
  },
  watchTradesAccount: function () {
    $('input#xero_setting_include_brokerage_in_trades').change(function () {
      Xero.updateBrokerageAccount(true);
    });
  },
};

documentReady(function () {
  if ($('#xero_settings_form').length) {
    Xero.updateInvoiceOptions(false);
    Xero.watchInvoiceOptions();
    Xero.updateBrokerageAccount(false);
    Xero.watchTradesAccount();
  }
});

window.Xero = Xero;
