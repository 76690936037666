import { documentReady } from 'app/documentReady';

let hasExtendedJQuery = false;
const extendJQuery = () => {
  if (hasExtendedJQuery || !$) return;
  hasExtendedJQuery = true;

  $.extend({
    trigger_plan_select(radio) {
      if (!(radio.length > 0)) {
        $.hide_all();
        return;
      }

      $.show_all();
      radio.closest('ul.plans').find('li').removeClass('selected');
      const current_li = radio.closest('li');
      current_li.addClass('selected');
      $.visualize_plan_list(current_li.attr('id'));
      const data_calculations = current_li.data('calculations');
      $.change_plan_calculations(data_calculations);
    },

    visualize_plan_list(key) {
      const map = {};
      map['plan_id_18'] = 'js-not-starter';
      if (typeof map[key] !== 'undefined') {
        $('.description li.off').removeClass('off', 600);
        $(`.description li.${map[key]}`).addClass('off', 600);
      } else {
        $('.description li.off').removeClass('off', 600);
      }
    },

    animate_in_out(object, callback) {
      return object.fadeTo('fast', 0.1, function () {
        if (typeof callback === 'function') {
          callback.call(object);
        }

        return object.fadeTo('slow', 1);
      });
    },

    change_plan_calculations(hash) {
      const select = $('.select-sum');
      const items = ['number_portfolios', 'price', 'total', 'save', 'not_in_use', 'tax_amount'];
      if (typeof hash['not_in_use'] === 'undefined') {
        select.find('#not_in_use').closest('.hint').hide();
      } else {
        select.find('#not_in_use').closest('.hint').show();
      }

      $.each(items, (index, item) =>
        $.animate_in_out(select.find(`#${item}`), function () {
          return $(this).html(hash[item]);
        })
      );
    },

    hide_all() {
      $('#index_pro .case-empty').show();
      $('#index_pro .case-selected').hide();
      $('#index_pro .description').hide();
      $('#index_pro .actions').hide();
    },

    show_all() {
      $('#index_pro .case-empty').hide();
      $('#index_pro .case-selected').show();
      $('#index_pro .description').show();
      $('#index_pro .actions').show();
    },
  });
};

extendJQuery();
documentReady(function () {
  extendJQuery();
  if (!$('.plans.index').length) return;

  const radios = ' ul.plans input[type=radio]';
  $.trigger_plan_select($(`${radios}:checked`));

  $(radios).change(function () {
    return $.trigger_plan_select($(this));
  });

  return $(' .js-change-plan').click(() => $(' .js-collapsable').slideToggle());
});
