import ExchangeRates from './exchange_rates';
import InstrumentPrice from './instrument_price_loader';

const Payout = {
  form_selector: '#payout__payout_form',
  portfolio_currency_code: '',
  instrument_currency_code: '',
  entered_fields: {},
  calculated_net_dividend: 0.0,
  user_exchange_rate: 1.0,
  payout_instrument_exchange_rate: 1.0,

  update_user_exchange_rate: function () {
    var paid_on = $('#paid_on').val();
    ExchangeRates.load_exchange_rate(
      Payout.payout_currency_code,
      Payout.portfolio_currency_code,
      paid_on
    );
  },

  update_payout_instrument_exchange_rate: function () {
    var paid_on = $('#paid_on').val();
    ExchangeRates.load_payout_instrument_exchange_rate(
      Payout.payout_currency_code,
      Payout.instrument_currency_code,
      paid_on
    );
  },

  set_exchange_rate: function (new_rate) {
    $('#exchange_rate_choice_exchange_rate').attr('checked', true);
    Payout.exchange_rate_choice_exchange_rate();
    $('#payout_user_exchange_rate').val(new_rate);
    Payout.calculate_new_exchange_rate_or_new_banked_amount();
  },

  set_loaded_price: function () {
    $('input#payout_drp_trade_attributes_price').val($('a.drp_price_value').html().trim());
    Payout.calculate_drp_total();
  },

  reload_instrument_price: function (instrument_id) {
    var goes_ex_on = $('input#goes_ex_on').val();
    var paid_on = $('input#paid_on').val();
    var traded_on = goes_ex_on !== null && goes_ex_on !== '' ? goes_ex_on : paid_on;
    InstrumentPrice.load_drp_instrument_price_by_id(instrument_id, traded_on);
  },

  calculate_totals: function () {
    var data_payout_currency = $(Payout.form_selector).data('payout-currency-code');
    var data_tax_credit_currency = $(Payout.form_selector).data('tax-credit-currency-code');

    var payout_amount = $('input#payout_amount').val();

    var franked_amount = $('input#payout_franked_amount').val();
    var unfranked_amount = $('input#payout_unfranked_amount').val();
    var tax_deferred_amount = $('input#payout_def_inc_trade_attributes_capital_return_value').val();
    var resident_withholding_tax_amount = $('input#payout_resident_withholding_tax').val();
    var non_resident_withholding_tax_amount = $('input#payout_non_resident_withholding_tax').val();
    var tax_credit_amount = $('input#payout_tax_credit').val();
    var extra_interest_payment_amount = $('input#payout_extra_interest_payment_amount').val();
    var capital_gains_amount = $('input#payout_capital_gains').val();
    var discounted_capital_gains_amount = $('input#payout_discounted_capital_gains').val();
    var foreign_source_income_amount = $('input#payout_foreign_source_income').val();
    var non_assessable = $('input#payout_non_assessable').val();
    var cgt_concession_amount = $('input#payout_cgt_concession_amount').val();
    var user_exchange_rate = $('#payout_user_exchange_rate').val();
    var payout_instrument_exchange_rate = $('#payout_instrument_exchange_rate a').html();
    var amit_decrease_amount = $(
      'input#payout_amit_decrease_trade_attributes_capital_return_value'
    ).val();
    var amit_increase_amount = $(
      'input#payout_amit_increase_trade_attributes_capital_return_value'
    ).val();

    payout_amount = !payout_amount ? 0 : Payout.as_decimal(parseFloat(payout_amount), 2);

    franked_amount = !franked_amount ? 0 : Payout.as_decimal(parseFloat(franked_amount), 2);
    unfranked_amount = !unfranked_amount ? 0 : Payout.as_decimal(parseFloat(unfranked_amount), 2);
    tax_deferred_amount = !tax_deferred_amount
      ? 0
      : Payout.as_decimal(parseFloat(tax_deferred_amount), 2);
    resident_withholding_tax_amount = !resident_withholding_tax_amount
      ? 0
      : Payout.as_decimal(parseFloat(resident_withholding_tax_amount), 2);
    non_resident_withholding_tax_amount = !non_resident_withholding_tax_amount
      ? 0
      : Payout.as_decimal(parseFloat(non_resident_withholding_tax_amount), 2);
    tax_credit_amount = !tax_credit_amount
      ? 0
      : Payout.as_decimal(parseFloat(tax_credit_amount), 2);
    extra_interest_payment_amount = !extra_interest_payment_amount
      ? 0
      : Payout.as_decimal(parseFloat(extra_interest_payment_amount), 2);
    capital_gains_amount = !capital_gains_amount
      ? 0
      : Payout.as_decimal(parseFloat(capital_gains_amount), 2);
    discounted_capital_gains_amount = !discounted_capital_gains_amount
      ? 0
      : Payout.as_decimal(parseFloat(discounted_capital_gains_amount), 2);
    foreign_source_income_amount = !foreign_source_income_amount
      ? 0
      : Payout.as_decimal(parseFloat(foreign_source_income_amount), 2);
    non_assessable = !non_assessable ? 0 : Payout.as_decimal(parseFloat(non_assessable), 2);
    cgt_concession_amount = !cgt_concession_amount
      ? 0
      : Payout.as_decimal(parseFloat(cgt_concession_amount), 2);
    user_exchange_rate = !user_exchange_rate
      ? 1
      : Payout.as_decimal(parseFloat(user_exchange_rate) || 1, 8);
    payout_instrument_exchange_rate = !payout_instrument_exchange_rate
      ? 1
      : Payout.as_decimal(parseFloat(payout_instrument_exchange_rate) || 1, 8);
    amit_decrease_amount = !amit_decrease_amount
      ? 0
      : Payout.as_decimal(parseFloat(amit_decrease_amount), 2);
    amit_increase_amount = !amit_increase_amount
      ? 0
      : -Payout.as_decimal(parseFloat(amit_increase_amount), 2);

    var total_income;
    var net_dividend;
    if (payout_amount > 0) {
      // Non-AU - use the payout amount as the net div
      net_dividend = payout_amount;
    } else {
      // Australian - total the payouty things and take off the taxy things
      total_income = Payout.as_decimal(
        franked_amount +
          unfranked_amount +
          tax_deferred_amount +
          extra_interest_payment_amount +
          capital_gains_amount +
          discounted_capital_gains_amount +
          foreign_source_income_amount +
          non_assessable +
          cgt_concession_amount +
          amit_decrease_amount +
          amit_increase_amount,
        2
      );
      var converted_rwt = resident_withholding_tax_amount * user_exchange_rate;
      net_dividend = Payout.as_decimal(
        total_income - converted_rwt - non_resident_withholding_tax_amount,
        2
      );
    }

    Payout.calculated_net_dividend = net_dividend;
    Payout.user_exchange_rate = user_exchange_rate;
    Payout.payout_instrument_exchange_rate = payout_instrument_exchange_rate;
    var gross_dividend = Payout.as_decimal(total_income + tax_credit_amount, 2);

    $('div#total_income div.dull span').html(total_income);
    $('div#net_dividend div.dull span').html(net_dividend);

    if (data_payout_currency === data_tax_credit_currency) {
      $('#gross_dividend div.dull span').html(gross_dividend);
    } else {
      $('#gross_dividend').hide();
    }
  },

  calc_drp_payout_payment_available: function () {
    var net_dividend_received = Payout.as_decimal(
      Payout.calculated_net_dividend / Payout.payout_instrument_exchange_rate,
      2
    );
    var drp_balance_bf = parseFloat($('#payout_drp_trade_attributes_drp_balance_bf').val());

    if (net_dividend_received || drp_balance_bf) {
      var payout_payment_available = (net_dividend_received || 0) + (drp_balance_bf || 0);
      return payout_payment_available;
    }
  },

  clear_user_entered: function () {
    this.entered_fields = {};
  },

  add_user_entered: function (id) {
    this.entered_fields[id] = true;
  },

  is_user_entered: function (id) {
    return id in this.entered_fields;
  },

  calculate_drp_total: function () {
    var net_dividend_received = Payout.as_decimal(
      Payout.calculated_net_dividend / Payout.payout_instrument_exchange_rate,
      2
    );
    $('#drp_net_dividend_received_value').text(net_dividend_received);

    var payment_available = this.calc_drp_payout_payment_available();
    if (payment_available) {
      $('#drp_payment_available').show();
      $('#drp_payment_available_value').html(Payout.as_decimal(payment_available, 2));
    } else {
      $('#drp_payment_available').hide();
    }

    var quantity = $('#payout_drp_trade_attributes_quantity').val();
    quantity = !quantity ? 0 : parseFloat(quantity);

    var price = $('#payout_drp_trade_attributes_price').val();
    price = !price ? 0 : parseFloat(price);

    if (!quantity && price > 0) {
      // [Re]calculate the quantity, unless the user has entered it already.
      if ($('#drp_mode_setting').text().trim() === 'up') {
        quantity = Math.ceil((payment_available * 100) / (price * 100));
      } else if ($('#drp_mode_setting').text().trim() === 'half') {
        quantity = Math.round((payment_available * 100) / (price * 100));
      } else if ($('#drp_mode_setting').text().trim() === 'decimal') {
        quantity = (payment_available * 100) / (price * 100);
      } else {
        // Round down
        quantity = Math.floor((payment_available * 100) / (price * 100));
      }

      // set the value
      $('#payout_drp_trade_attributes_quantity').val(quantity);
    }

    var total = (quantity * (price * 10000)) / 10000;

    $('div#drp_total_price span.drp_total').html(Payout.as_decimal(total, 2));
    $('div#drp_total_price').effect('highlight', {}, 500);

    var drp_balance_cf = payment_available - total;
    if (drp_balance_cf < 0) drp_balance_cf = 0;
    $('#drp_balance_cf_value').text(Payout.as_decimal(drp_balance_cf, 2));
  },

  exchange_rate_choice_exchange_rate: function () {
    $('#user_entering_exhange_rate').show();
    $('#user_entering_banked_amount').hide();
    $('#payout_user_exchange_rate').attr('disabled', false);
    $('#payout_banked_amount').attr('disabled', true);
  },
  exchange_rate_choice_banked_amount: function () {
    $('#user_entering_exhange_rate').hide();
    $('#user_entering_banked_amount').show();
    $('#payout_user_exchange_rate').attr('disabled', true);
    $('#payout_banked_amount').attr('disabled', false);
  },
  calculate_new_exchange_rate_or_new_banked_amount: function () {
    var user_exchange_rate = $('#payout_user_exchange_rate').val();
    var banked_amount = $('#payout_banked_amount').val();
    var amount = Payout.calculated_net_dividend;

    try {
      var v;
      if ($('#exchange_rate_choice_exchange_rate').is(':checked')) {
        if (user_exchange_rate > 0 && amount > 0) {
          v = parseFloat(amount) / parseFloat(user_exchange_rate);
        } else {
          v = '';
        }
        $('#payout_banked_amount').val(Payout.as_decimal(v, 2));
        $('#user_banked_amount_text').effect('highlight', {}, 500);
      } else {
        v = parseFloat(banked_amount) * parseFloat(amount);
        if (banked_amount > 0 && amount > 0) {
          v = parseFloat(amount) / parseFloat(banked_amount);
        } else {
          v = '';
        }
        $('#payout_user_exchange_rate').val(Payout.as_decimal(v, 8));
        $('#user_exchange_rate_text').effect('highlight', {}, 500);
      }
    } catch (e) {
      alert(e);
    }
    // copy textfield values to display fields
    $('#user_exchange_rate_text').html($('#payout_user_exchange_rate').val());
    $('#user_banked_amount_text').html($('#payout_banked_amount').val());
    // Also calculate drp available
    Payout.calculate_drp_total();
  },

  as_decimal: function (val, decimalPlaces = 2) {
    if (isNaN(parseFloat(val))) return 0;
    return Math.round(val * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  },

  checkForDeletedTradeAndPromptAndDrpAmount: function () {
    if (
      !$('input#payout_drp_trade_attributes_dividend_reinvested').is(':checked') &&
      !confirm(
        'You have deselected the dividend reinvestment. This will delete the dividend reinvestment trade.'
      )
    ) {
      return false;
    }
    return Payout.checkForDrpAmount();
  },

  readNetDividend: function () {
    var value;
    if (
      $('#exchange_rate_choice_banked_amount').length > 0 &&
      $('#exchange_rate_choice_banked_amount').is(':checked')
    ) {
      value = $('input#payout_banked_amount').val();
    } else if ($('div#net_dividend').length > 0) {
      value = $('div#net_dividend div.dull span').html();
    } else {
      value = $('input#payout_amount').val();
    }
    return Payout.as_decimal(parseFloat(value), 2);
  },

  checkForDrpAmount: function () {
    if ($('input#payout_payout_description_id_3').is(':checked')) {
      return true;
    }
    if (!$('input#payout_drp_trade_attributes_dividend_reinvested').is(':checked')) {
      return true;
    }

    var payout_available = this.calc_drp_payout_payment_available();
    var drp_total = Payout.as_decimal(
      parseFloat($('div#drp_total_price span.drp_total').html()),
      2
    );
    var difference_amount_drp = Payout.as_decimal(Math.abs(payout_available - drp_total), 2);
    var drp_price = parseFloat($('input#payout_drp_trade_attributes_price').val());
    if (
      difference_amount_drp > drp_price &&
      !confirm(`
        The difference between the available payment (${Payout.as_decimal(payout_available, 2)})
        and the value of shares obtained via reinvestment (${drp_total}) is ${difference_amount_drp}.
        This amount is greater than the dividend reinvestment share price (${drp_price}), are you sure this is correct?
      `)
    ) {
      return false;
    }
    return true;
  },
};

window.Payout = Payout;
export default Payout;
