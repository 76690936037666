import { documentReady } from './documentReady';

documentReady(function () {
  $('#js-lock-button').click(function (e) {
    e.preventDefault();
    $(this).attr('data-confirm', $('#js-lock-form').html());
  });

  $(document).on('click', 'button[data-lock-only]', function () {
    $('input#lock_lock_only').val($(this).data('lock-only'));
  });
});
