class ForeignPayout {
  constructor() {
    if (!$("span[data-foreign='true']").length) return;
    window.Payout.portfolio_currency_code = $('span[data-portfolio-currency-code]').data(
      'portfolio-currency-code'
    );
    window.Payout.payout_currency_code = $('span[data-payout-currency-code]').data(
      'payout-currency-code'
    );
    window.Payout.update_user_exchange_rate();
    $('#payout_amount, #payout_user_exchange_rate, #payout_banked_amount').bind(
      'change',
      window.Payout.calculate_new_exchange_rate_or_new_banked_amount
    );
    $('#exchange_rate_choice_exchange_rate').bind(
      'change',
      window.Payout.exchange_rate_choice_exchange_rate
    );
    $('#exchange_rate_choice_banked_amount').bind(
      'change',
      window.Payout.exchange_rate_choice_banked_amount
    );
    if ($('#exchange_rate_choice_exchange_rate').is(':checked')) {
      window.Payout.exchange_rate_choice_exchange_rate();
    } else {
      window.Payout.exchange_rate_choice_banked_amount();
    }
    $(document).on('click', 'a.exchange_rate_value', function () {
      if (!$('input#exchange_rate_choice_banked_amount').is(':checked')) {
        window.Payout.set_exchange_rate($(this).html());
      }
      return false;
    });
    $('#paid_on').change(window.Payout.update_user_exchange_rate);
  }
}

class Drp {
  constructor() {
    window.Payout.instrument_currency_code = $('span[data-holding-currency]').data(
      'holding-currency'
    );
    $('#paid_on').change(function () {
      window.Payout.update_payout_instrument_exchange_rate();
    });

    if (!$("span[data-drp-price-guessed='true']").length) return;
    const instrument_id = $('span[data-instrument-id]').data('instrument-id');

    $('#goes_ex_on').change(function () {
      const goes_ex_on = $('input#goes_ex_on').val();
      if (goes_ex_on !== null && goes_ex_on !== '') {
        window.Payout.reload_instrument_price(instrument_id);
      }
    });
    $('#paid_on').change(function () {
      const goes_ex_on = $('input#goes_ex_on').val();
      if (goes_ex_on === null || goes_ex_on === '') {
        window.Payout.reload_instrument_price(instrument_id);
      }
    });
    $(document).on('click', 'a.drp_price_value', window.Payout.set_loaded_price);
  }
}

window.PayoutFormAfterRender = function () {
  if (!$('#payout__payout_form').length) return;

  window.Payout.portfolio_currency_code = '';
  window.Payout.payout_currency_code = '';

  new ForeignPayout();
  new Drp();

  const triggers = [
    'input#payout_amit_decrease_trade_attributes_capital_return_value',
    'input#payout_amit_increase_trade_attributes_capital_return_value',
    'input#payout_amount',
    'input#payout_capital_gains',
    'input#payout_cgt_concession_amount',
    'input#payout_def_inc_trade_attributes_capital_return_value',
    'input#payout_discounted_capital_gains',
    'input#payout_extra_interest_payment_amount',
    'input#payout_foreign_source_income',
    'input#payout_franked_amount',
    'input#payout_non_assessable, ' + 'input#payout_non_resident_withholding_tax',
    'input#payout_resident_withholding_tax',
    'input#payout_tax_credit',
    'input#payout_unfranked_amount',
  ];
  $(document).on('change', triggers.join(','), function () {
    window.Payout.calculate_totals();
    window.Payout.calculate_drp_total();
    window.Payout.calculate_new_exchange_rate_or_new_banked_amount();
  });

  $(document).on(
    'change',
    '#payout_drp_trade_attributes_quantity, ' +
      '#payout_drp_trade_attributes_price, ' +
      '#payout_drp_trade_attributes_drp_balance_bf',
    function (event) {
      window.Payout.add_user_entered(event.target.id);
      window.Payout.calculate_drp_total();
    }
  );

  return $('#payout_drp_trade_attributes_dividend_reinvested').click(function () {
    $('#drp_trade_block').toggle();
  });
};

var attachHandlers = () => {
  window.initialisePayoutForm();
  window.initDatePickers();
  window.LoadInline.initLoadInline();

  const modal_overlay = $('#modal_overlay');
  const form = modal_overlay.find('form');

  form.on('ajax:error', (e, jqXHR) => {
    if (jqXHR && jqXHR.status === 401) {
      return (window.location = '/');
    }
  });

  form.one('submit', () => {
    // When ajax is successful wait until the data
    // is loaded to the dom and init again
    return modal_overlay.one('overlay-ajax-loaded', attachHandlers);
  });

  // Delete payout button
  form.find('a[data-method="delete"]').one('click', () => {
    return modal_overlay.one('overlay-ajax-loaded', attachHandlers);
  });

  // Reject unconfirmed payout button
  return form.find('a[data-method="patch"]').one('click', () => {
    return modal_overlay.one('overlay-ajax-loaded', attachHandlers);
  });
};

window.Overlay.setHistoryHandler('payouts', () => attachHandlers());

window.initialisePayoutForm = function () {
  window.PayoutFormAfterRender();
  window.radioContentSwitcher.initModule($('*[data-radio-content-switcher]'));
  window.payoutFieldControl.initModule($('.js-payout-fields-control'));

  $(document).on('radioContentSwitcher-changed payoutFieldControl-field-closed', function () {
    window.Payout.calculate_totals();
    return window.Payout.calculate_drp_total();
  });

  window.Payout.calculate_totals();
  window.Payout.calculate_drp_total();
  return window.fileUploadAttachHandlers();
};
