import browserUpdate from 'browser-update';

import analytics from '@sharesight/react/dist/helpers/analytics';

// Styled in: `app/assets/stylesheets/brandings/app_default/partials/unsupported_browser.less`

const getMetaData = obj => {
  if (!obj || !obj.browser) return;

  return {
    already_shown: obj.already_shown,
    browser: obj.browser.t,
    mobile: !!obj.browser.mobile,
    age_years: obj.browser.age_years,
  };
};

document.addEventListener('DOMContentLoaded', function () {
  // See: http://browser-update.org/customize.html
  browserUpdate({
    required: {
      e: -4, // Edge: >= 4 versions behind (means IE11 is disallowed)
      c: -5, // Chrome: >= 5 versions behind
      o: -5, // Opera: >= 5 versions behind
      f: -5, // Firefox: >= 5 versions behind
      s: '11.1.1', // At least iOS Safari 11.1
      ios: '11.1.1', // At least iOS Safari 11.1
      samsung: 10,
    },

    // Don't notify browsers that will are no longer updated by the vendor (eg. Chrome).
    // These settings notify recent versions, rather than sticking to the following the `required` list above.
    insecure: false, // this notified Safari 12.4 (within 1 version)
    unsupported: false, // this notified Chrome 80 (within 1 version)

    reminder: 12, // re-appear after 12 hours
    reminderClosed: 168, // if closed with "ignore", re-appear after 168 hours (7 days)
    no_permanent_hide: true, // users are not allowed to permanently hide this

    newwindow: true, // open browser update link in a new tab
    nostatistics: true, // don't send statistics to `browser-udpate`

    onshow: function (obj) {
      analytics.track('unsupported_browser__shown', getMetaData(obj));
    },
    onclick: function (obj) {
      analytics.track('unsupported_browser__update', getMetaData(obj));
    },
    onclose: function (obj) {
      analytics.track('unsupported_browser__ignored', getMetaData(obj));
    },
  });
});
