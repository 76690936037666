import jQuery from 'jquery';

export default class LinkLock {
  // Should work with dom or jquery object and selector
  constructor(el) {
    this.lock = this.lock.bind(this);
    this.autoLock = this.autoLock.bind(this);
    if (el instanceof jQuery) {
      this.link = el;
    } else {
      this.link = jQuery(el);
    }
  }

  nothing() {
    return false;
  }

  lock() {
    return this._bindLock(this.nothing);
  }

  unlock() {
    return this.link.unbind('click', this.nothing);
  }

  // Binding indirectly to make it more testable
  _bindLock(fn) {
    return this.link.click(fn);
  }

  autoLock() {
    return this._bindLock(this.lock);
  }
}

window.LinkLock = LinkLock;
