import analytics from '@sharesight/react/dist/helpers/analytics';

import { initDatepickerSingle, formatInputDate } from './datepicker';

window.setupBulkImport = function (stepIndex) {
  // 0 - File upload form
  // 1 - Column mapping form
  // 2 - Trade(s) import form
  switch (stepIndex) {
    case 0:
      analytics.track(`file_importer__render_file_upload_form`);
      break;
    case 1:
      analytics.track(`file_importer__render_column_mapping_form`);
      break;
    case 2:
      analytics.track(`file_importer__render_trades_import_form`);
      break;
    default:
    // do nothing
  }

  $('#js-bulk-import-type-switcher').change(function () {
    $('.js-bulk-import-info').hide();
    return $(`.js-bulk-import-info-${$(this).val()}`)
      .show()
      .effect('highlight', {}, 3000);
  });

  if ($('#show-upgrade-message').length) {
    $('div#upgrade_message').show();
  }

  // form#import == Choose a file form
  $(document).on('submit', 'form#import', function () {
    analytics.track(`file_importer__submit_upload`, {
      file_is_attached: !!$('input[name=trades_csv_file]').val(),
    });
  });

  // 'form#form_bulk_import_column_selection' == Column Selection form
  $(document).on('submit', 'form#form_bulk_import_column_selection', function () {
    if (BulkImportColumnSelection.checkOneTypeSelected()) {
      const button = $(this).find('button');
      button.prop('disabled', true);
      button.html('Please wait...');
      return true;
    }

    return false;
  });

  if ($('#js-bulk-import-form').length > 0) {
    const importer = new BulkImport($('#js-bulk-import-form'));

    $(
      "tr[data-bulk-errors='true'], div[data-bulk-success-trade='true'], div[data-bulk-fail-trade='true']"
    ).hide();

    $('#js-dialog-lock-warning-submit').click(function () {
      $('#js-dialog-lock-warning').modal('hide');
      return importer.saveTrades();
    });

    // Submit all transactions on the page, grouped by market code and instrument code (one post per group)
    // "Save all transaction" button
    $("input[data-action='js-bulk-import-submit']").click(e => {
      analytics.track(`file_importer__click_save_all`);
      importer.saveClicked(e);
    });

    // Add a generic row (bottom of the page)
    $('#js-add-row-button').click(function (e) {
      analytics.track(`file_importer__click_add_row`);
      e.preventDefault();
      return importer.addRow();
    });

    // saved trades, click link scroll to saved trades table at bottom of the page
    $('a#bulk-scroll-to-saved').click(() =>
      $('html, body').animate(
        { scrollTop: $('div#js-bulk-import-saved-trades').offset().top },
        1000
      )
    );

    // Add a row at the market/instrument code level
    $(document).on('click', "a[data-action='js-add-row-button-holding']", function (e) {
      analytics.track(`file_importer__click_add_row_for_holding`);
      e.preventDefault();
      importer.addRow($(this).data('code'), $(this).data('market'));
      return importer.validateMaxTrades();
    });

    // Checkbox for delete the transaction
    const jsDestroyers = $('.js-destroyer');
    jsDestroyers.each(function () {
      return importer.markDeleted($(this));
    });

    // Submit the transactions only for this particular market code and instrument code
    // "Save [code]" button
    $(document).on('click', 'button[data-action="js-bulk-import-submit-holding"]', function (e) {
      analytics.track(`file_importer__click_save_holding`, {});
      return importer.saveClicked(e, $(this).data('code'), $(this).data('market'));
    });

    // Submit the transactions only for this particular market code and instrument code, for a negative position validation issue only
    $(document).on('click', 'button[data-action="import-anyway"]', function (e) {
      return importer.importAnywayClicked(e, $(this));
    });

    // Changing the code in the group of market code and instrument code, updates all hidden code values and data attributes
    $(document).on('keyup', 'input[data-action="change-all-code"]', function () {
      return importer.updateHoldingCodeTransactions($(this));
    });

    // Same as above, for mobile...
    $(document).on('change', 'input[data-action="change-all-code"]', function () {
      return importer.updateHoldingCodeTransactions($(this));
    });

    // Changing the market in the group of market code and instrument code, updates all hidden market values and data attributes
    $(document).on('change', 'select[data-action="change-all-market"]', function () {
      return importer.updateHoldingMarketTransactions($(this));
    });

    // Checkbox for delete the transaction
    return $(document).on('click', '.js-destroyer', function () {
      importer.markDeleted($(this));
      return importer.validateMaxTrades();
    });
  }
};

class BulkImport {
  constructor(form) {
    this.form = form;
    this.table = this.form.find('table');
    this.spinner = this.form.find('.outer-spinner');
    this.rowSelector = 'tr.js-bulk-import-trade[data-saved=false]';
    this.marketSelector = '.js-bulk-import-trade-market';
    this.codeSelector = '.js-bulk-import-trade-code';
    this.errorDetailsSelector = 'tr.error_details';
    this.instrumentHeaderSelector = 'tr.instrument_header';
    this.instrumentFooterSelector = 'tr.instrument_footer';
    this.hiddenClass = 'js-hidden-trade';
    this.deletedClass = 'deleted';
    this.savedTrades = $('#js-bulk-import-saved-trades');
    this.tableWithSavedTrades = this.savedTrades.find('table');
    this.successCountSelector = "div[data-bulk-success-trade='true']";
    this.failCountSelector = "div[data-bulk-fail-trade='true']";
    this.saveAllTransactionsSelector = "input[data-action='js-bulk-import-submit']";
    this.should_send_to_xero = '#should_send_to_xero';

    this.validateMaxTrades();
  }

  // When we count more than X trades per holding
  // we disable the submit buttons and display an error flash message
  validateMaxTrades() {
    let all_valid = true;
    const $global_submit = $("*[data-action='js-bulk-import-submit']");

    return (() => {
      const result = [];
      const object = this.allTradesMap();
      for (let _i in object) {
        const d = object[_i];
        const $holding_submit = d.$instrument_footer.find(
          "*[data-action='js-bulk-import-submit-holding']"
        );
        const trades_count = d.trades_to_import.length;
        if (trades_count > 300) {
          all_valid = false;
          $holding_submit.prop('disabled', true);
          const msg = `You cannot import more than 300 trades per holding. You have ${trades_count} trades, please remove a few.`;

          // Update message, or insert row element
          if (d.$instrument_header.prev().hasClass('error-too-many-trades')) {
            d.$instrument_header.prev().find('.flashmessage').html(msg);
          } else {
            const flash_tr = `<tr class="error-too-many-trades"><td colspan="10"><div class="flashmessage error">${msg}</div></td></tr>`;
            d.$instrument_header.before(flash_tr);
          }
        } else {
          if (d.$instrument_header.prev().hasClass('error-too-many-trades')) {
            d.$instrument_header.prev().remove();
          }
          $holding_submit.prop('disabled', false);
        }

        if (all_valid) {
          result.push($global_submit.prop('disabled', false));
        } else {
          result.push($global_submit.prop('disabled', true));
        }
      }
      return result;
    })();
  }

  allTradesMap() {
    const res = {};
    const $header_rows = $('.table.import tr.instrument_header[data-code]');
    $header_rows.each(function (index) {
      const $next_rows = $(this).nextUntil('tr.instrument_header');
      return (res[index] = {
        $instrument_header: $(this),
        $instrument_footer: $next_rows.filter('.instrument_footer'),
        instrumentCoding: `${$(this).data('code')}.${$(this).data('market')}`,
        trades_to_import: $next_rows
          .filter('.js-bulk-import-trade.unhandled:not(.js-hidden-trade):not(.deleted)')
          .toArray(),
      });
    });
    return res;
  }

  // When Save button is clicked
  saveClicked(e, code, market) {
    e.preventDefault();
    let waitingForDialog = false;
    const dialogLock = $('#js-dialog-lock-warning');
    if (dialogLock.length > 0 && !code && !market) {
      const lockedFields = $('input.lock-date-field');
      lockedFields.each(function () {
        if (
          !waitingForDialog &&
          (window.PortfolioLock.alwaysWarn || window.PortfolioLock.lockFound(this))
        ) {
          waitingForDialog = true;
          return dialogLock.modal('show');
        }
      });
    }
    if (!waitingForDialog) {
      if (code && market) {
        return this.saveTrades($.trim(code).toUpperCase(), market);
      } else {
        return this.saveTrades();
      }
    }
  }

  importAnywayClicked(e, elem) {
    const trParent = elem.closest('tr');
    let code = trParent.data('code');
    let market = trParent.data('market');
    code = $.trim(code).toUpperCase();
    market = $.trim(market).toUpperCase();
    trParent
      .nextUntil(`tr.instrument_footer[data-code='${code}'][data-market='${market}']`)
      .find('input[data-relax-validation=true]')
      .val('true'); // mark all relax validation for this trades to true
    return this.saveClicked(e, code, market);
  }

  // When market value is changed in the group of transactions per market and code
  updateHoldingMarketTransactions(marketElem) {
    const codeValue = $.trim(
      marketElem.parents('tr').find('input[data-action="change-all-code"]').val()
    ).toUpperCase();
    const marketValue = marketElem.val();
    const tradesElements = marketElem.parents('tr').nextUntil(this.instrumentFooterSelector).next();
    $.each(tradesElements, (i, row) => {
      return this.updateTransactionsData($(row), codeValue, marketValue);
    });
    return this.updateTransactionsData(marketElem.parents('tr'), codeValue, marketValue);
  }

  // When code value is changed in the group of transactions per market and code
  updateHoldingCodeTransactions(codeElem) {
    const marketValue = codeElem
      .parents('tr')
      .find('select[data-action="change-all-market"]')
      .val();
    const codeValue = $.trim(codeElem.val()).toUpperCase();
    const tradesElements = codeElem.parents('tr').nextUntil(this.instrumentFooterSelector).next();
    $.each(tradesElements, (i, row) => {
      return this.updateTransactionsData($(row), codeValue, marketValue);
    });
    return this.updateTransactionsData(codeElem.parents('tr'), codeValue, marketValue);
  }

  // Called from updateHoldingCodeTransactions and updateHoldingMarketTransactions, to update proper values / data attributes
  updateTransactionsData(row, code, market) {
    code = $.trim(code).toUpperCase();
    row.attr('data-code', code);
    row.attr('data-market', market);
    row.find('[data-market]').data('market', market);
    row.find('[data-code]').data('code', code);
    row.find('[data-action="selector-market"]').val(market);
    row.find('[data-action="selector-code"]').val(code);
    return row.find('[type=submit]').html(`Save ${code}`);
  }

  // Called when checkbox for delete is clicked
  markDeleted(checkbox) {
    const row = checkbox.closest('tr');
    const prevRow = row.prev();
    if (checkbox.prop('checked')) {
      row.addClass('deleted');
      if (prevRow.hasClass('error_details')) {
        return prevRow.addClass('deleted');
      }
    } else {
      row.removeClass('deleted');
      if (prevRow.hasClass('error_details')) {
        return prevRow.removeClass('deleted');
      }
    }
  }

  // Called when adding an extra row on the form
  addRow(code, market) {
    let hidden;
    if (code && market) {
      hidden = $(
        $(
          `.${this.hiddenClass}[data-code='${$.trim(code).toUpperCase()}'][data-market='${market}']`
        )[0]
      );
    } else {
      hidden = $(`.${this.hiddenClass}[data-for-all='true']`);
    }
    const trade = hidden.clone().insertBefore(hidden).removeClass(this.hiddenClass);
    trade.show().effect('highlight', {}, 2000);
    const datepicker = trade.find('.datepicker');
    return datepicker.attr('id', this.randomId);
  }

  // Called from saveTrades, for grouping by market and code
  marketsAndCodes(rows, code_only, market_only) {
    const result = {};
    $.each(rows, (index, row) => {
      const market = this.marketElement(row).value;
      this.codeElement(row).value = this.codeElement(row).value.toUpperCase();
      const code = $.trim(this.codeElement(row).value).toUpperCase();
      if (!result[market]) {
        result[market] = [];
      }
      if (code_only && market_only) {
        code_only = $.trim(code_only).toUpperCase();
        if (code === code_only && market === market_only) {
          result[market].push(code);
        }
      } else {
        result[market].push(code);
      }

      return $.unique(result[market]);
    });

    return result;
  }

  // Called form saveTrades, to select only rows for that particual market and code, or all, depending which save button was clicked
  rowsForMarketAndCode(code, market) {
    const allRows = $(this.rowSelector);
    if (!code || !market) {
      return allRows;
    }
    code = $.trim(code).toUpperCase();
    const ret = $.grep(allRows, row => {
      return (
        this.marketElement(row).value === market &&
        this.codeElement(row).value.toUpperCase() === code
      );
    });
    return $(ret);
  }

  // Generates a random ID (timestamp)
  randomId() {
    return `randomId_${new Date().getTime()}`;
  }

  // Return the market per row
  marketElement(row) {
    return $(row).find(this.marketSelector)[0];
  }

  // Return the code per row
  codeElement(row) {
    return $(row).find(this.codeSelector)[0];
  }

  // Return true if the row has been saved
  isSaved(row) {
    return row.data('saved') || row.hasClass(this.hiddenClass) || row.hasClass(this.deletedClass);
  }

  // Gives all unsaved rows
  unsavedRows(rows, options) {
    return $(
      rows
        .map((index, row) => {
          const $row = $(row);

          if (Object.keys(options || {}).length > 1) {
            if (
              this.marketElement(row).value === options['market'] &&
              this.codeElement(row).value === options['code']
            ) {
              if (!this.isSaved($row)) return row;
            }
          } else if (!this.isSaved($row)) {
            return row;
          }
        })
        .get()
    );
  }

  // Serialize the rows to be sent in the post request
  serializeRows(rows) {
    let rows_data = rows
      .map((index, row) => $(':input', row).serialize())
      .get()
      .join('&');
    const trades_type = this.form.find('#trades_type');
    if (rows_data) {
      const force_xero_flag = $(this.should_send_to_xero);
      if (force_xero_flag.length > 0) {
        rows_data += `&should_send_to_xero=${force_xero_flag[0].checked}`;
      }
      if (trades_type.length > 0) {
        return `${rows_data}&type=${trades_type.val()}`;
      } else {
        return rows_data;
      }
    }
  }

  // when request is being posted
  closeCurtain() {
    $(this.saveAllTransactionsSelector).hide();
    this.table.animate({ opacity: 0.2 });
    this.savedTrades.animate({ opacity: 0.2 });
    $('#js-bulk-import-form > .actions').animate({ opacity: 0.2 });
    return this.spinner.show();
  }

  // when request finishes
  openCurtain() {
    this.table.animate({ opacity: 1.0 });
    this.savedTrades.animate({ opacity: 1.0 });
    $('#js-bulk-import-form > .actions').animate({ opacity: 1.0 });
    $(this.saveAllTransactionsSelector).show();
    this.spinner.hide();
    return this.cleanUpDOM(); // This code makes me feel dirtier than the DOM....
  }

  // remove current errors when transactions are submitted/resubmitted
  removeCurrentErrors(code, market) {
    if (code && market) {
      return this.table
        .find(`tr.text-error[data-code="${$.trim(code).toUpperCase()}"][data-market="${market}"]`)
        .remove();
    } else {
      return this.table.find('tr.text-error').remove();
    }
  }

  // before sending trades for saving, hide current saved/failed counters
  hideCounters() {
    $(this.successCountSelector).hide();
    return $(this.failCountSelector).hide();
  }

  // update saved/failed counters and show or keep them hidden if 0 value
  handleCounters() {
    let text;
    let total_saved = 0;
    let total_failed = 0;
    total_saved = $('div#js-bulk-import-saved-trades table tbody tr').length;
    const erroredRows = this.table.find('tr[data-bulk-errors="true"]:visible');
    $.each(erroredRows, (index, row) => {
      const failedTrades = $(row).nextUntil(this.instrumentFooterSelector);
      return $.each(failedTrades, function () {
        if (!$(this).is(':hidden') && $(this).hasClass('instrument_body')) {
          return total_failed++;
        }
      });
    });
    const total_saved_elem = $(this.successCountSelector).find('a');
    const total_failed_elem = $(this.failCountSelector).find('span');
    if (total_saved > 0) {
      text = 'trade';
      if (total_saved > 1) {
        text += 's';
      }
      total_saved_elem.html(`${total_saved} ${text}`);
      $(this.successCountSelector).show();
    }
    if (total_failed > 0) {
      text = 'trade';
      if (total_failed > 1) {
        text += 's';
      }
      total_failed_elem.html(`${total_failed} ${text}`);
      return $(this.failCountSelector).show();
    }
  }

  // called when posts are done, if there are still unsaved rows, do nothing, otherwise redirects
  iterationsFinished() {
    const rows = $(this.rowSelector);
    const numUnsaved = this.unsavedRows(rows, {}).length;
    this.handleCounters(); // update and in case show counters for saved / failed trades
    if (numUnsaved === 0) {
      $(this.saveAllTransactionsSelector).hide();
      return (window.location = this.form.data('portfolio-url'));
    }
  }

  cleanUpDOM() {
    const first_headers = this.table.find('.instrument_header');
    return $.each(first_headers, (i, el) => $(el).prev('tr.heading-col-names:visible').remove());
  }

  // Main call that groups transactions by market and code and post them to the server, handles the response, controls the UI, render state changes, change the DOM, authorisation, access control, coffee making, tea brewing, toastie grilling and general cleaning, I guess...
  saveTrades(code, market) {
    let $headers, macs;
    this.table.find(this.errorDetailsSelector).detach();
    if (code && market) {
      code = $.trim(code).toUpperCase();
      this.table
        .find(`${this.instrumentHeaderSelector}[data-code='${code}'][data-market='${market}']`)
        .detach();
      this.table
        .find(`${this.instrumentFooterSelector}[data-code='${code}'][data-market='${market}']`)
        .detach();
      $headers = $(`[data-market='${market}'][data-code='${code}']`);
    } else {
      this.table.find(this.instrumentHeaderSelector).detach();
      this.table.find(this.instrumentFooterSelector).detach();
      $headers = $('.heading-col-names');
    }

    // Remove all errors before trying to save
    this.removeCurrentErrors();

    $headers.hide();
    const rows = this.rowsForMarketAndCode(code, market);
    rows.hide();
    this.closeCurtain();
    this.removeCurrentErrors(code, market);

    // hash of markets with codes
    if (code && market) {
      macs = this.marketsAndCodes(rows, code, market);
    } else {
      macs = this.marketsAndCodes(rows);
    }

    if ($('div#upgrade_message').length !== 0) {
      $('div#upgrade_message').hide();
    } // hide upgrade message if shown

    // convert to Array
    const macsa = [];
    for (let m in macs) {
      const codes = macs[m];
      for (let c of [...codes]) {
        macsa.push([m, c]);
      }
    }

    let i = 0;
    var nextIteration = () => {
      // iterate per market/code, post, handle result...
      if (i === macsa.length) {
        this.openCurtain();
        this.iterationsFinished();
        return;
      }

      const mac = macsa[i++];
      const rows_to_send = this.unsavedRows(rows, { market: mac[0], code: mac[1] });
      const data = this.serializeRows(rows_to_send);
      if (data && data.length > 0) {
        return $.ajax({
          type: 'post',
          data,
          url: this.form.attr('action'),
          beforeSend: () => {
            return rows_to_send.slice(1).detach();
          }, // remove all but one entries for this instrument
          success: (data, status, jqXHR) => {
            if (jqXHR.status === 201) {
              // trades have been saved
              $(rows_to_send[0]).detach();
              this.tableWithSavedTrades.append(data);
              this.savedTrades.show();
              $headers.remove();
            } else {
              // trades are not saved
              $(this.failCountSelector)
                .find('span')
                .html(
                  parseInt($(this.failCountSelector).find('span').html()) + rows_to_send.length
                );
              $(rows_to_send[0]).replaceWith(data);

              // iterate through _every_ datepicker and initialize it..
              // This is because we're getting new dates from an html XHR and we need to format them..
              // NOTE: This is bad and inefficient: using $(this.form) as there's no way to get the replaced element..
              $('.datepicker', $(this.form)).each((index, datepicker) => {
                formatInputDate(datepicker);
                initDatepickerSingle.call(datepicker);
              });
            }

            return nextIteration();
          },
          error: (xhr /*, status , err */) => {
            this.handleServerError(xhr);
          },
        });
      } else {
        return nextIteration();
      }
    };

    // start iterations
    this.hideCounters(); // hide counters for saved / failed trades
    return nextIteration();
  }

  handleServerError(err) {
    let msg = `We're sorry, but something went wrong.<br> \
We've been notified about this issue and we'll take a look at it as soon as we can.`;
    if (err.status && err.status.toString().match(/^504/i)) {
      // Server time outs
      msg = `We're sorry, but our server took too long to process your file. <br> \
Please check that your CSV file contains less than 500 trades and remove any superfluous columns`;
    }
    const flash = `<div class="flashmessage error"> ${msg}</div>`;
    return $('form#js-bulk-import-form').html(flash);
  }
}

var BulkImportColumnSelection = {
  checkOneTypeSelected() {
    $('select.columns_selection').each(function (index, e) {
      $(e).removeClass('double_selection');
    });
    const column_already_selected = [];
    let column_selection_ok = true;
    $('div#message_column_selection').hide();
    $('select.columns_selection').each(function (index, e) {
      if (column_selection_ok === false) {
        return;
      }
      const option = $(e).find('option:selected');
      const option_value = $(option[0]).val();
      if (option_value !== null && option_value !== '' && option_value !== 'undefined') {
        if ($.inArray(option_value, column_already_selected) >= 0) {
          column_selection_ok = false;
          $(`select.columns_selection option:contains(${option_value}):selected`).each(function (
            index,
            e
          ) {
            $(e).parent().addClass('double_selection');
          });
          $('div#message_column_selection')
            .html(
              `You selected the column "<b>${option_value}</b>" twice. Please check and try again.`
            )
            .effect('highlight', {}, 6000);
          $('div#message_column_selection').show();
          return;
        }
        column_already_selected.push(option_value);
      }
    });
    return column_selection_ok;
  },
};
