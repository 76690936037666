import analytics from '@sharesight/react/dist/helpers/analytics';
import { documentReady } from './documentReady';

documentReady(() => {
  window.addEventListener('beforeinstallprompt', event => {
    // Track whether or not the Add to Home Screen prompt was shown.
    analytics.track('a2hs__prompted');

    event.userChoice?.then(choice => {
      // Track whether or not the Add to Home Screen prompt was shown.
      if (choice?.outcome) {
        analytics.track(`a2hs__${choice.outcome}`, { platforms: event.platforms });
      }
    });
  });

  window.addEventListener('appinstalled', () => {
    // If the app was installed by another means…
    analytics.track('a2hs__installed');
  });

  /**
   * Whether or not the user is currently visiting in a standalone PWA.
   * This works because our manifest file contains `display: "standalone"`
   * @see: https://stackoverflow.com/a/51160283
   */
  const isStandalone =
    window.matchMedia?.('(display-mode: standalone)')?.matches ||
    window.navigator?.standalone === true;

  if (isStandalone) {
    analytics.track('a2hs__visited');
  }
});
