// NOTE: These will ONLY be available after the document is ready and some time has passed!

let GlobalVars = {
  screen_phone: 480,
  screen_tablet: 768,
  screen_desktop: 992,
  screen_lg_desktop: 1200,
  user_country: '',
  initialized: {},
};

document.addEventListener('DOMContentLoaded', function () {
  // global javascript variables are set by views
  const jsonSelector = document.getElementById(`user_settings`);
  if (jsonSelector?.innerHTML) {
    const json = JSON.parse(jsonSelector.innerHTML);

    // NOTE there is intentional mutation by assigning to itself.
    // We want to update all references to the export or window.GlobalVars!
    GlobalVars = Object.assign(GlobalVars, json);
  }
});

window.GlobalVars = GlobalVars;
export default GlobalVars;
