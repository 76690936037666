//
// Allows radio buttons to show or hide a certain container classes
// via its value (to show) and data attribute (to toggle/hide).
//
// - each radio button has a data-radio-content-switcher which contains a selector
//   to identify elements that should be shown when the button is checked and hidden when it
//   isn't
//
// Usage example (haml): see cash_accounts/new.html.haml
//

import { documentReady } from './documentReady';

var radioContentSwitcher = (function ($) {
  var initModule,
    currentStates,
    showContentForCurrent,
    setupVars,
    observeRadios,
    getUnselected,
    configMap = {
      // Specify the data tag that we use to identify the switcher radio buttons (by its presence)
      // and the fields that are marked as visible when the radio button is checked
      data_selector: 'radio-content-switcher',
      // If set, don't hide/show fields where a value has been filled in
      data_selector_ignore_prefilled_inputs: 'settings-ignore-prefilled-inputs',
    },
    stateMap = {
      current_selection: null,
      all_selectors_map: null,
    },
    objMap = {
      $selectors: [],
    };

  initModule = function ($selectors) {
    objMap.$selectors = $selectors;

    if (objMap.$selectors.lenght < 0) {
      return false;
    }
    setupVars();
    showContentForCurrent();
    observeRadios();
  };

  setupVars = function () {
    var all = [];
    objMap.$selectors.each(function () {
      all.push($(this).data(configMap.data_selector));
    });
    stateMap.current_selection = objMap.$selectors.filter(':checked').data(configMap.data_selector);
    stateMap.all_selectors_map = all;
  };

  showContentForCurrent = function (clear_values) {
    var $to_hide,
      $input,
      ignore_this_one,
      to_hide_map = stateMap.all_selectors_map,
      to_show = `.${stateMap.current_selection}`,
      settings_ignore_prefilled = objMap.$selectors
        .filter(':checked')
        .data(configMap.data_selector_ignore_prefilled_inputs);

    $.each(to_hide_map, function (i, to_hide_selector) {
      $(`.${to_hide_selector}`).each(function () {
        ignore_this_one = false;
        $to_hide = $(this);
        $input = $to_hide.find('input[type=text]');

        if (settings_ignore_prefilled && $.trim($input.val()) === '') {
          ignore_this_one = true;
        }

        if (!ignore_this_one) {
          $to_hide.hide();
          if (clear_values === true) {
            $input.val('');
          }
        }
      }); //end each to_hide
    });
    $(to_show).show();
  };

  observeRadios = function () {
    objMap.$selectors.on('change', function () {
      stateMap.current_selection = $(this).data(configMap.data_selector);
      showContentForCurrent(true);
      $(this).trigger('radioContentSwitcher-changed');
    });
  };

  getUnselected = function () {
    if (stateMap.all_selectors_map.length === 0) {
      return [];
    }
    if (!stateMap.current_selection || stateMap.current_selection.length === 0) {
      return stateMap.all_selectors_map;
    }
    return $.grep(stateMap.all_selectors_map, function (a) {
      return a !== stateMap.current_selection;
    });
  };

  currentStates = function () {
    return {
      radios: objMap.$selectors,
      all: stateMap.all_selectors_map,
      selected: stateMap.current_selection,
      unselected: getUnselected(),
    };
  };

  return {
    initModule: initModule,
    currentStates: currentStates,
  };
})(jQuery);

window.radioContentSwitcher = radioContentSwitcher;

documentReady(function () {
  radioContentSwitcher.initModule($('*[data-radio-content-switcher]'));
});
