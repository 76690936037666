import 'jquery.cookie'; // NOTE: Requires jQuery, which is shimmed in the pack that includes this file.
import { documentReady } from './documentReady';

var DollarPercentSwitch = (function () {
  const selectorMap = {
    switch_container: '.dollar-percent-switch ',
    switch_link_percent: '.dollar-percent-switch .btn.switch-to-percent ',
    switch_link_dollar: '.dollar-percent-switch .btn.switch-to-dollar ',
    values_percent: '.js-double-value > span.percent',
    values_dollar: '.js-double-value > span:not(.percent)',
  };
  const stateMap = { mode: null };

  let iniModule = undefined;
  let showDollars = undefined;
  let showPercent = undefined;

  DollarPercentSwitch = class DollarPercentSwitch {
    static initClass() {
      iniModule = function () {
        if ($(selectorMap.values_percent).is(':visible')) {
          return showPercent();
        } else {
          return showDollars();
        }
      };

      showDollars = function () {
        stateMap.mode = 'dollar';
        $(selectorMap.values_percent).parent().hide();
        $(selectorMap.values_dollar).parent().show();
        $(selectorMap.switch_link_dollar).addClass('active');
        $(selectorMap.switch_link_percent).removeClass('active');
        $.cookie('show_as', 'D', { expires: 365, path: '/' });
        return window.Sortable.rebuild_cache();
      };

      showPercent = function () {
        stateMap.mode = 'percent';
        $(selectorMap.values_dollar).parent().hide();
        $(selectorMap.values_percent).parent().show();
        $(selectorMap.switch_link_dollar).removeClass('active');
        $(selectorMap.switch_link_percent).addClass('active');
        $.cookie('show_as', 'P', { expires: 365, path: '/' });
        return window.Sortable.rebuild_cache();
      };
    }

    constructor() {
      if (!$(selectorMap.switch_container).length) return false;
      iniModule();
      window.Sortable.setup_sorting();

      // On click dollar/percent
      $(document).on('click', selectorMap.switch_link_dollar, () => showDollars());
      $(document).on('click', selectorMap.switch_link_percent, () => showPercent());

      // Initialize again when main data gets reloaded. E.g. Portfolio overview js-reload-main effect
      $(document).on('main_reloaded', () => iniModule());
    }
  };

  DollarPercentSwitch.initClass();
  return DollarPercentSwitch;
})();

documentReady(() => {
  new DollarPercentSwitch();
});
