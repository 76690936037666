// drop file event problem (multiple times)

import { documentReady } from './documentReady';

// window.fileAlreadyDropped = false
window.dropZoneTimeout = null;

documentReady(() => {
  $(document).bind('dragover', function (e) {
    const dropZone = $('#dropzone');
    const timeout = window.dropZoneTimeout;

    if (!timeout) {
      dropZone.addClass('in');
    } else {
      clearTimeout(timeout);
    }

    let found = false;
    let node = e.target;
    while (node) {
      if (node === dropZone[0]) {
        found = true;
        break;
      }

      node = node.parentNode;
    }

    if (found) {
      dropZone.addClass('hover');
    } else {
      dropZone.removeClass('hover');
    }

    return (window.dropZoneTimeout = setTimeout(function () {
      window.dropZoneTimeout = null;
      dropZone.removeClass('in hover');
    }, 100));
  });
});
