import 'bootstrap'; // NOTE: Requires jQuery, which is shimmed in the pack that includes this file.

// Show/Hide fields in payout form - this dynamically generates the 'Add another field' dialog
// by making unuual use of a popover. The button is given a popover which renders the select
// dropdown.
//
// Requires Bootstrap Popover Plugin: http://getbootstrap.com/javascript/#popovers
// => when enabled using .popover on the element, this causes a click on the element to render the content of the data-content attribute.
const payoutFieldControl = (function ($) {
  const popoverContainerHtml = `
    <div class="popover-container field text-center">
      <a href="javascript:" class="btn btn-default btn-xs btn-block popover-button">
        <i class="ico ico-plus"></i> Add another field
      </a>
    </div>
  `;

  const configMap = {
    // jQuery expression to select fields from the container that should be available through 'Add another field', if not already shown
    availableSelectFields: '.field:not(.field-total):not(.popover-container)',

    // Apply this class (in static HTML) to any fields that should be shown by default.
    defaultVisibleFields: '.payout-fields-control-default-visibility',

    popoverButtonContainer: '.popover-container', // Class that is applied to the containing div for the each button
    popoverButton: '.popover-button', // click this to open the popover itself

    popoverTooltip: '.popover[role="tooltip"]', // the popover (eg. tooltip)
    popoverContent: '.popover[role="tooltip"] .popover-content', // the content inside said popover/tooltip
  };

  const stateMap = {
    fields_maps: [],
    to_ignore_map: [],
  };

  let $container; // this is used in most functions below

  // This builds a nested array of field names for each column
  // where the field appears in the container and is not marked as to ignore.
  // e.g: [['Discounted capital gains', 'Foreign source income',...],['Foreign tax witheld',...],[...]]
  function getFieldNameMaps() {
    let columns_map = []; // an array like [nested_map, nested_map, nested_map]

    $container.each(function () {
      const nested_map = [];
      $(this)
        .find(configMap.availableSelectFields)
        .each(function () {
          const $field = $(this);
          let ignore_this_field = false;

          $.each(stateMap.to_ignore_map, function (index, class_name) {
            if ($field.hasClass(class_name)) {
              ignore_this_field = true;
            }
          });

          if (ignore_this_field === true) return true;

          if ($field.is(':hidden')) {
            const label_text = $.trim(
              $field.find('label').clone().children().remove().end().text()
            );
            nested_map.push(label_text);
          }
        });

      columns_map.push(nested_map);
    });

    return columns_map;
  }

  // This finds all the fields in $field's container that are selectors
  function getAllParentFields($field) {
    return $field.parents($container).first().find(configMap.availableSelectFields);
  }

  // Make an array of select elements, one for each container
  function getSelectsHtmlMap() {
    let selects_map = [];

    stateMap.fields_maps = getFieldNameMaps();
    $.each(stateMap.fields_maps, function (i, fields_map) {
      let html_select = '';
      let html_options = '';

      $.each(fields_map, function (i, name) {
        html_options += `<option value="${name}">${name}</option>`;
      });

      if (html_options) {
        html_select = `<select class="form-control">
          <option>-- select a new field ---</option>
          ${html_options}
        </select>`;
      }

      selects_map.push(html_select);
    });

    return selects_map;
  }

  // Render the 'add' button selection details into each button
  // Will be called on init and when we either select or hide a field
  function renderButtons(reset) {
    if ($("span[data-disable-input-textarea='true']").length) {
      // non editor access
      return;
    }

    const radio_switcher_unselected_map = window.radioContentSwitcher.currentStates().unselected;
    if (radio_switcher_unselected_map.length > 0) {
      stateMap.to_ignore_map = radio_switcher_unselected_map;
    }

    if (reset) {
      // Ugly bugfix, to ensure selections are not accessable within the window
      $(configMap.popoverTooltip).css({ left: '-9999px' });
      $(configMap.popoverContent).html('');

      $(configMap.popoverButtonContainer).remove(); // remove all old buttons
    }

    // Inject HTML into the page, just above the total field.
    $container.find('.field-total').before(popoverContainerHtml);

    // Iterate over each button and initialize the popover.
    const selects_html_map = getSelectsHtmlMap();
    $container.find(configMap.popoverButton).each(function (index) {
      const select_html = selects_html_map[index];
      if (!select_html) {
        // do not render button
        $(this).remove();
      } else {
        // Initialize each popover!
        $(this).popover({
          sanitize: false, // NOTE: We MUST not sanitize, else our HTML will be discarded.  This is because we're using a select in this popover.
          html: true,
          container: '#payout_modal',
          placement: 'bottom',
          content: select_html,
        });
      }
    });

    renderCloseButtons();
  }

  // Add a close button to each optional field that is currently displayed
  function renderCloseButtons() {
    const btn =
      '<button type="button" class="close"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>';

    $container.find(configMap.availableSelectFields).each(function () {
      const $field = $(this);

      // If any of the above is truthy, we don't render a close button (eg. it already exists, it's visible by default, etc)
      if (getAllParentFields($field).length < 2) return true;
      if ($field.is(configMap.popoverButtonContainer)) return true;
      if ($field.find('button.close').length !== 0) return true;
      if ($field.is(configMap.defaultVisibleFields)) return true;

      // render button
      $field.prepend(btn);
    });
  }

  // Called on init to hide fields that don't default to visible and don't have a value already
  function hideInvalidFields() {
    $container.each(function () {
      $(this)
        .find(configMap.availableSelectFields)
        .each(function () {
          let hide_this_field = false;
          let ignore_this_field = false;
          const $field = $(this);

          // validations
          if (getAllParentFields($field).length < 2) ignore_this_field = true;
          if ($.trim($field.find('input').val()) === '') hide_this_field = true;
          if ($field.is(configMap.defaultVisibleFields)) ignore_this_field = true;

          // hide field if valid
          if (!ignore_this_field && hide_this_field) {
            $field.hide();
          }
        });
    });
  }

  // Hide the add button when all the selectable fields are displayed
  function closePopups(target) {
    // The target is inside of a popover itself.
    const tooltips = $(configMap.popoverTooltip);
    if (tooltips.has($(target)).length || tooltips.is(target)) {
      return true;
    }

    $(configMap.popoverButton).popover('hide');
  }

  // Called from when the user selects a field to add. Makes that field visible
  function showFieldWithLabel(label_name) {
    const $field = $(`${configMap.availableSelectFields}:contains(${label_name})`);

    $field.show('fade', null, 700, function () {
      $field.find('input').focus();
    });
  }

  // Called when the user clicks the close button on a dynamic field
  function hideField($field) {
    $field.find('input').val('');
    $field.hide();
    $field.trigger('payoutFieldControl-field-closed');
    renderButtons(true);
    resizeOverlay();
  }

  // Called when the user selects a field to add
  function selectField($select) {
    showFieldWithLabel($select.val());
    $(configMap.popoverButton).popover('hide');
    renderButtons(true);
    resizeOverlay();
  }

  function resizeOverlay() {
    // The overlay iframe has to be adjusted to the contents height!
    // see overlay.js
    if (typeof Overlay === 'object') {
      window.Overlay.auto_resize_parent_iframe();
    }
  }

  // Initialize the 'add another field' popup
  // $container is a Jquery selector that selects all the div containers for each flavour of field
  // (income e.g. franked amount / tax credits e.g. witholding tax / statistical values e.g;. franking credits)
  // Each $container will have an 'Add another field' selector enabled in it which will show all the unselected fields
  function initModule(container) {
    if (!container || !container.length) return false;
    $container = container;

    hideInvalidFields();
    renderButtons();

    // POPUP: show on click
    $(document).on('click', configMap.popoverButton, function () {
      // $field.parents($container).first().find(configMap.availableSelectFields+":visible").last();
      if ($(`${configMap.popoverContent}:visible`).length < 1) {
        $(this).popover('show');
      }
    });

    // POPUP: on selecting an option
    $(document).on('change', `${configMap.popoverContent} select`, function () {
      selectField($(this));
    });

    // POPUP: Close all when clicking outside
    $(document).on('mouseup', function (e) {
      closePopups(e.target);
    });

    // CLOSE BUTTON: hide this field
    $(document).on('click', `${configMap.availableSelectFields} button.close`, function () {
      closePopups();
      hideField($(this).parents(configMap.availableSelectFields));
    });

    // RADIO SELECTION: on change reset fields and buttons
    if (window.radioContentSwitcher.currentStates().radios.length > 0) {
      $(window.radioContentSwitcher.currentStates().radios).on(
        'radioContentSwitcher-changed',
        function () {
          hideInvalidFields();
          renderButtons(true);
        }
      );
    }

    hideInvalidFields();
  }

  return {
    initModule: initModule,
  };
})(jQuery);

window.payoutFieldControl = payoutFieldControl;

// NOTE: This is initialized whenever a Payout Overlay loads, see `./payout_form.js` (search for `window.payoutFieldControl`).
