import { documentReady } from './documentReady';

const initAjaxForms = () => {
  const afs = [];

  $(document).on('ajax:before', "form[data-ajax-form='true']", () => afs.push(1));

  $(document).on('ajax:beforeSend', "form[data-ajax-form='true']", function () {
    if (afs.length !== 1) return false;
  });

  $(document).on('ajax:complete', "form[data-ajax-form='true']", function (data, status /* xhr */) {
    const match_elem = $(this).closest('div[data-place-ajax-html-result="true"]');

    // Redirect when it is a json { type: "redirect", location: "/something" }
    const json_body = status.responseJSON;
    if (json_body && json_body.type === 'redirect') {
      // disable all buttons to prevent double-submits
      match_elem.find('input[type="submit"]').prop('disabled', true);
      afs.pop();
      window.location = json_body.location;
      return;
    }

    // Default: Render new html content in the div container
    match_elem.html(status.responseText);
    if (
      $(this).data('reload-after-save') &&
      !status.responseText.match('Sorry, we have found (an error|some errors):')
    )
      window.location.reload();
    return afs.pop();
  });
};

documentReady(() => {
  initAjaxForms();
});
