import { number } from '@sharesight/react/dist/helpers/formatter';

const errorHandler = jqXHR => {
  if (jqXHR && jqXHR.status === 401) window.location = '/';
};

export const ExchangeRates = {
  load_exchange_rate: function (from_code, to_code, on) {
    ExchangeRates.load_selected_exchange_rates(from_code, to_code, on, true, true);
  },

  load_base_exchange_rate: function (from_code, to_code, on) {
    ExchangeRates.load_selected_exchange_rates(from_code, to_code, on, true, false);
  },

  load_market_exchange_rate: function (from_code, to_code, on) {
    ExchangeRates.load_selected_exchange_rates(from_code, to_code, on, false, true);
  },

  load_payout_instrument_exchange_rate: function (from_code, to_code, on) {
    if (from_code === to_code) return;
    if (!from_code || !to_code) return;

    if (!on) on = 'TODAY';
    const url = `/exchange_rates/${from_code}/${to_code}?date=${on}`;

    if (!this.exchange_rate_query_cache[url]) {
      this.exchange_rate_query_cache[url] = $.get(url);
    }

    return this.exchange_rate_query_cache[url]
      .done(data => {
        const exchangeRateElement = $('#payout_instrument_exchange_rate');
        exchangeRateElement.html(data);
        window.Payout.calculate_totals();
        window.Payout.calculate_drp_total();
      })
      .fail(errorHandler);
  },

  loaded_exchange_rates: function () {
    var jsonRates = $('#exchange_rates_json').text();
    if (!jsonRates) return {};

    return JSON.parse(jsonRates);
  },

  brokerage_rates: function (portfolio_currency_code, instrument_currency_code, denominations) {
    var rates = ExchangeRates.loaded_exchange_rates();
    var wanted_keys = Object.keys(rates).filter(k => k.slice(4, 7) == portfolio_currency_code);

    var res = new Object();
    for (var key of wanted_keys) {
      var currency_code = key.slice(0, 3);
      res[currency_code] = rates[key].rate;
      if (currency_code == instrument_currency_code) {
        // Might have multiple denominations scaled off the rate
        for (const k in denominations) {
          if (k != instrument_currency_code) {
            res[k] = denominations[k] * rates[key].rate;
          }
        }
      }
    }
    return res;
  },

  exchange_rate_query_cache: {},

  load_all_exchange_rates: function (from_code, portfolio_currency_code, on, show_all_crosses) {
    $('#exchange_rate_loading_indicator').show();

    if (!on) on = 'TODAY';
    var url = `/exchange_rates/${from_code}?date=${on}&portfolio_currency=${portfolio_currency_code}&show_all_crosses=${show_all_crosses}`;

    if (!this.exchange_rate_query_cache[url]) {
      this.exchange_rate_query_cache[url] = $.get(url);
    }

    return this.exchange_rate_query_cache[url]
      .done(exchange_rates => {
        $('#exchange_rates_json').text(JSON.stringify(exchange_rates));
        $('#exchange_rate_loading_indicator').hide();
      })
      .fail(errorHandler);
  },

  // exchange_rates, as returned from the server, is a map of
  // { code: { rate: <rate>, on: <date> } with code = XXXYYY where XXX and YYY are ISO currency codes
  find_exchange_rate_value: function (exchange_rates, from_currency_code, to_currency_code) {
    const formatterOptions = { decimal_places: 8, decimal_places_minimum: 2, delimiter: false };

    if (
      !Object.keys(exchange_rates)
        .map(r => r.slice(0, 3))
        .includes(from_currency_code)
    ) {
      // Use the inverse value
      const current_rate = exchange_rates[`${to_currency_code}/${from_currency_code}`];
      if (!current_rate) return null;

      return {
        on: current_rate.on,
        rate_s: number.to(1.0 / current_rate.rate, formatterOptions),
      };
    }

    const current_rate = exchange_rates[`${from_currency_code}/${to_currency_code}`];
    if (!current_rate) return null;

    return {
      on: current_rate.on,
      rate_s: number.to(current_rate.rate, formatterOptions),
    };
  },

  load_selected_exchange_rates: function (from_code, to_code, on, base, market) {
    if (!base && !market) return;
    if (from_code === to_code) return;
    if (!from_code || !to_code) return;
    if ($("span[data-disable-input-textarea='true']").length) return; // input edit access is disabled

    const exchangeRateLoadingElement = $('#exchange_rate_loading_indicator');
    const marketPriceExchangeRateLoadingElement = $(
      '#market_price_exchange_rate_loading_indicator'
    );

    if (!on) on = 'TODAY';
    const url = `/exchange_rates/${from_code}/${to_code}?date=${on}`;

    if (!this.exchange_rate_query_cache[url]) {
      if (base) exchangeRateLoadingElement.show();
      if (market) marketPriceExchangeRateLoadingElement.show();
      this.exchange_rate_query_cache[url] = $.get(url);
    }

    return this.exchange_rate_query_cache[url]
      .done(data => {
        const exchangeRateElement = $('#exchange_rate');
        const marketPriceExchangeRateElement = $('#market_price_exchange_rate');

        if (base) {
          exchangeRateLoadingElement.hide();
          exchangeRateElement.html(data);
          exchangeRateElement.show();
        } else {
          exchangeRateElement.hide();
        }

        if (market && $('#market_price_exchange_rate').length > 0) {
          marketPriceExchangeRateLoadingElement.hide();
          $('#holding_trade_market_price_exchange_rate').val(data.match(/<a.*>(.*)<\/a>/)[1]);
          marketPriceExchangeRateElement.html(data);
          marketPriceExchangeRateElement.show();
        } else {
          marketPriceExchangeRateElement.hide();
        }
      })
      .fail(errorHandler);
  },
};

window.ExchangeRates = ExchangeRates;
export default ExchangeRates;
