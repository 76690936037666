export const Utils = {
  touch_image(image_id) {
    const image = document.getElementById(image_id);
    // NOTE: This is some top-tier horrible code that appears to do nothing, but I can't actually replicate the functionality.
    // It's POSSIBLE that by replacing the image with any (the same) image, it refreshes the spinner animation, or something...
    if (image != null) {
      return (image.src = image.src); // eslint-disable-line no-self-assign
    }
  },

  show_spinner(image_id) {
    const image = document.getElementById(image_id);
    if (image != null) {
      image.style.display = 'inline';
      return setTimeout(`Utils.touch_image('${image_id}')`, 500);
    }
  },
};

window.Utils = Utils;
export default Utils;
