import { documentReady } from 'app/documentReady';

const togglePortfolioAccessCheckboxes = function (check) {
  const checkboxes = $('div[data-portfolio-list] input');
  if ($('input#owner_staff_member_has_access_to_all_portfolios_true').is(':checked')) {
    checkboxes.prop('disabled', true);
    if (check) {
      checkboxes.prop('checked', true);
    }
    $('input#filter_by_portfolio_name').hide();
    return false;
  }
  checkboxes.prop('disabled', false);
  if (check) {
    checkboxes.prop('checked', false);
  }

  return $('input#filter_by_portfolio_name').show();
};

const toggleAccountLevelCustomInvestmentsCheckbox = function () {
  const checkbox = document.getElementById(
    'owner_staff_member_can_manage_account_level_custom_investments'
  );

  const all_access_is_false = document.getElementById(
    'owner_staff_member_has_access_to_all_portfolios_false'
  );

  const all_access_is_true = document.getElementById(
    'owner_staff_member_has_access_to_all_portfolios_true'
  );

  if (all_access_is_false.checked) {
    checkbox.checked = false;
    checkbox.disabled = true;
  }

  if (all_access_is_true.checked) {
    checkbox.checked = false;
    checkbox.disabled = false;
  }
};

const handleSelectedOnlyOrAllRadio = function () {
  togglePortfolioAccessCheckboxes(true);
  toggleAccountLevelCustomInvestmentsCheckbox();
};

const filterByPortfolioName = () =>
  $(document).on('keyup', 'input#filter_by_portfolio_name', function () {
    const portfolioName = $(this).val().toLowerCase();
    if (portfolioName.length > 0) {
      $('div[data-portfolio-list]').hide();
      return $.each($('div[data-portfolio-list]'), function () {
        if ($(this).data('portfolio-name').match(portfolioName)) {
          return $(this).show();
        }
      });
    } else {
      return $('div[data-portfolio-list]').show();
    }
  });

const initPage = function () {
  if (!$('input#owner_staff_member_has_admin_access').length) {
    return;
  }
  if ($('input#owner_staff_member_has_admin_access').is(':checked')) {
    $('span[data-portfolio-selection]').hide();
    $('input#filter_by_portfolio_name').hide();
  } else {
    $('span[data-access-to-all-portfolios]').hide();
    $('input#filter_by_portfolio_name').show();
  }

  togglePortfolioAccessCheckboxes();

  const all_access_is_false = document.getElementById(
    'owner_staff_member_has_access_to_all_portfolios_false'
  );

  if (all_access_is_false.checked) {
    const checkbox = document.getElementById(
      'owner_staff_member_can_manage_account_level_custom_investments'
    );

    checkbox.checked = false;
    checkbox.disabled = true;
  }

  if ($('input#filter_by_portfolio_name').length > 0) {
    filterByPortfolioName();
  }

  return $('div#portfolios-list :checkbox').shiftcheckbox();
};

documentReady(function () {
  initPage();

  $(document).ajaxComplete(() => initPage());

  $(document).on('change', 'input#owner_staff_member_has_admin_access', function () {
    if ($(this).is(':checked')) {
      $('span[data-portfolio-selection]').hide();
      $('span[data-access-to-all-portfolios]').show();
      $('input#owner_staff_member_has_billing_access').prop('checked', true);
      $('input#owner_staff_member_has_manage_portfolios_access').prop('checked', true);
      $('input#owner_staff_member_can_edit_custom_groups').prop('checked', true);
      $('input#owner_staff_member_can_manage_account_level_custom_investments').prop(
        'checked',
        true
      );
      $('input#owner_staff_member_has_billing_access').prop('disabled', true);
      $('input#owner_staff_member_has_manage_portfolios_access').prop('disabled', true);
      $('input#owner_staff_member_can_edit_custom_groups').prop('disabled', true);
      $('input#owner_staff_member_can_manage_account_level_custom_investments').prop(
        'disabled',
        true
      );
      return false;
    }
    $('span[data-access-to-all-portfolios]').hide();
    $('span[data-portfolio-selection]').show();
    $('input#owner_staff_member_has_billing_access').prop('disabled', false);
    $('input#owner_staff_member_has_manage_portfolios_access').prop('disabled', false);
    $('input#owner_staff_member_can_edit_custom_groups').prop('disabled', false);
    $('input#owner_staff_member_has_billing_access').prop('checked', false);
    $('input#owner_staff_member_has_manage_portfolios_access').prop('checked', false);

    toggleAccountLevelCustomInvestmentsCheckbox();

    return $('input#owner_staff_member_can_edit_custom_groups').prop('checked', false);
  });

  return $(document).on(
    'change',
    'input#owner_staff_member_has_access_to_all_portfolios_false, input#owner_staff_member_has_access_to_all_portfolios_true',
    () => handleSelectedOnlyOrAllRadio()
  );
});
