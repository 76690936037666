import 'bootstrap'; // NOTE: Requires jQuery, which is shimmed in the pack that includes this file.
import bugsnag from '@sharesight/react/dist/helpers/bugsnag';
import { documentReady } from './documentReady';

export const Overlay = {
  container: $('#modal_overlay'),
  last_page_content_loaded: null,
  destroy_content_on_close: false,
  reload_on_close_url: false, // accepts boolean or string
  history: [],
  historyHandlers: {},
  initialised: false,

  setHistoryHandler: (pattern, handler) => {
    Overlay.historyHandlers[pattern] = handler;
  },

  unsetHistoryHandler: pattern => {
    delete Overlay.historyHandlers[pattern];
  },

  _matchHistoryHandler: path => {
    const keys = Object.keys(Overlay.historyHandlers);
    for (let l = keys.length, i = 0; i < l; i++) {
      const pattern = keys[i];
      if (path.match(pattern)) {
        return pattern;
      }
    }
    return false;
  },

  executeHistoryHandler: url => {
    const pattern = Overlay._matchHistoryHandler(url);
    if (pattern != false) {
      Overlay.historyHandlers[pattern]();
    }
  },

  show_spinner_img() {
    Overlay.container.find('.modal-body').html('');
    Overlay.container.find('.modal-content').css('min-height', '230px'); // div.modal-spinner height
    const $spinner = Overlay.container.find('.modal-spinner');
    if (!$spinner.length) {
      const img = $(
        '<div class="modal-spinner" style="display:none;"><i class="ico ico-spinner ico-2x"></i></div>'
      );
      Overlay.container.find('.modal-content').prepend(img);
      img.fadeIn(300);
    } else {
      $spinner.fadeIn(300);
    }
  },

  hide_spinner_img() {
    const $spinner = Overlay.container.find('.modal-spinner');
    if ($spinner.length) {
      $spinner.fadeOut(300, function () {
        $(this).parent().css('min-height', '0px'); // div.modal-spinner height
      });
    }
  },

  close_overlay() {
    Overlay.container.modal('hide');
    Overlay.container.trigger('overlay-closed');
  },

  close_overlay_and_refresh() {
    Overlay.close_overlay();
    $.remove_hash_from_url();
    window.location.reload();
  },

  // Triggered when Overlay closes/is hidden
  on_hidden() {
    $.remove_hash_from_url();
    if (Overlay.destroy_content_on_close) {
      Overlay.container.find('.modal-body').html('');
      Overlay.last_page_content_loaded = null;
    }
    if (Overlay.reload_on_close_url) {
      const url = Overlay.reload_on_close_url;
      if (typeof url === 'string') {
        window.location = url;
      }
      if (typeof url === 'boolean') {
        window.location.reload();
      }
    }
  },

  start() {
    Overlay.container.removeClass('modal-warning');
    Overlay.container.modal('show');
  },

  auto_resize_iframe(elem) {
    if (elem) {
      setTimeout(function () {
        const newheight = elem.contentWindow.document.body.offsetHeight;
        elem.height = `${newheight}px`;
      }, 100); // after the other JS changed the DOM
    }
  },

  go_back(steps = 1) {
    let url = Overlay.history[Overlay.history.length - (steps + 1)];
    if (!url) url = '';
    window.location.hash = url; // doesn't always work because of checks if any links that point to this url are placed on the page
    $(document).on('overlay-no-link-found', Overlay.container, function () {
      // No link to this overlay was found on this page?
      // Assume that no dom element exist either: no $.trigger_overlay_dom_replacement()
      // In iframes you better do not use it.
      // So trigger ajax only:
      $.trigger_overlay_ajax(url);
    });
    Overlay.history.pop();
  },

  auto_resize_parent_iframe() {
    window.parent.Overlay.auto_resize_iframe(window.parent.Overlay.container.find('iframe')[0]);
  },

  resize_iframe_on_clicks() {
    $(document).on('click', '.iframed', function () {
      Overlay.auto_resize_parent_iframe();
    });
  },

  links_to_parent_window(elem) {
    $(document).on('click', `${elem} #page a`, function (e) {
      const href = $(this).attr('href');
      const regex = new RegExp(/^#|xhr_request=true|^javascript:/);
      if (!regex.test(href)) {
        e.preventDefault();
        if ($(this).attr('target') === '_blank') {
          window.parent.open(href, '_blank');
        } else {
          window.open(href, '_parent');
        }
      }
    });
  },

  init_ajax_form_inside_overlay() {
    if (Overlay.initialised) return;
    const form = Overlay.container.find('form[data-remote=true]');

    $(document).on('ajax:success', form, function (data, status) {
      const to_render = typeof status.responseText === 'undefined' ? status : status.responseText;
      Overlay.container.find('.modal-body').html(to_render);
      $('#modal_overlay').trigger('overlay-ajax-loaded');
    });

    $(document).on('ajax:error', form, function (data, status) {
      const to_render = typeof status.responseText === 'undefined' ? status : status.responseText;
      Overlay.container.find('.modal-body').html(to_render);
      $('#modal_overlay').trigger('overlay-ajax-loaded');
    });

    Overlay.initialised = true;
  },

  make_draggable() {
    // make click on dialog close this thing
    $(document).on('click', '.modal-dialog', function (e) {
      if (e.target === $(this)[0]) Overlay.close_overlay();
    });

    const $content = Overlay.container.find('.modal-content');
    if (!$content.length) return;

    Overlay.container.on('show.bs.modal', function () {
      // reset position from draggable effects
      $content.css('left', '').css('top', '');
    });

    $content.draggable({
      handle: '.modal-dragfield',
    });
  },
}; // end Overlay

export function overlayUrl(url, destroyOnClose = true) {
  window.location.hash = url;
  Overlay.destroy_content_on_close = destroyOnClose;
}

let hasExtendedJQuery = false;
const extendJQuery = () => {
  if (hasExtendedJQuery || !$) return;
  hasExtendedJQuery = true;

  $.extend({
    trigger_overlay_sys_msg() {
      const path = Overlay.container.data('show-overlay');
      if (path) {
        window.location.hash = path;
        // won't find link, so fire it directly
        $.trigger_overlay_ajax(path);
        Overlay.start();
        if (!Overlay.container.hasClass('modal-warning')) {
          Overlay.container.addClass('modal-warning');
        }
      }
    },

    trigger_overlay_ajax(url) {
      if (Overlay.container.length > 0) {
        const href = url;

        // When successful login to SAXO the url returned is `/lst/a_number` this
        // url is not valid to be called below and a modal pop up is not required.
        if (url.includes('lst/')) return;

        if (Overlay.last_page_content_loaded !== href) {
          // page not already loaded in overlay
          Overlay.last_page_content_loaded = href;
          const elem_to_update = Overlay.container.find('.modal-body');
          Overlay.show_spinner_img();

          $.get(
            {
              url: href,
              dataType: 'html',
            },
            function (response, status, jqXHR) {
              // We may only load HTML content into overlays for security reasons.
              // Match text/html in "text/html; charset=utf-8" of "charset=utf-8; text/html"
              const is_html = jqXHR
                .getResponseHeader('Content-Type')
                .match(/(^|; )text\/html(;|$)|(^|; )text\/plain(;|$)/);
              if (!is_html) {
                elem_to_update.html(`<div class="flashmessage error">
                <strong>Error</strong>
                <p>Invalid response content type.</p>
              </div>`);
                Overlay.hide_spinner_img();

                // Notify us via bugsnag:
                bugsnag.notify(new Error('Bad Ajax Overlay Content Type'), event => {
                  event.addMetadata('sharesight', {
                    contentType: jqXHR.getResponseHeader('Content-Type'),
                    url: window.location.href,
                  });

                  event.severity = 'info';
                  event.context = 'overlay.js';
                });
                return;
              }

              elem_to_update.html(response);
              Overlay.executeHistoryHandler(url);
              if (status === 'success') {
                Overlay.hide_spinner_img();
                Overlay.init_ajax_form_inside_overlay(href);
                $('#modal_overlay').trigger('overlay-ajax-loaded');
              } else if (jqXHR.status === 401) {
                window.location = '/';
              }
            }
          );
        }

        Overlay.start();
      }
    },

    trigger_overlay_iframe(url) {
      if (Overlay.container.length > 0) {
        if (Overlay.last_page_content_loaded !== url) {
          const elem_to_update = Overlay.container.find('.modal-body');
          Overlay.show_spinner_img();
          Overlay.last_page_content_loaded = url;
          const url_to_add = `${
            Overlay.last_page_content_loaded.indexOf('?') === -1 ? '?' : '&'
          }xhr_request=true`;
          elem_to_update.html(
            $('<iframe />')
              .attr('height', '200')
              .attr('scrolling', 'no')
              .attr('marginheight', '0')
              .attr('frameborder', '0')
              .attr('onLoad', 'Overlay.auto_resize_iframe(this)')
              .attr('src', Overlay.last_page_content_loaded + url_to_add)
              .css('width', '100%')
          );
          $('#modal_overlay').trigger('overlay-ajax-loaded');
        }
        Overlay.start();
        Overlay.executeHistoryHandler(url);
      }
    },

    trigger_overlay_dom_replacement(element) {
      if (Overlay.container.length > 0) {
        const source_element = $(element);
        const elem_to_update = Overlay.container.find('.modal-body');
        elem_to_update.html(source_element.clone().show());

        Overlay.last_page_content_loaded = null;
        Overlay.hide_spinner_img();
        Overlay.start();
        $('#modal_overlay').trigger('overlay-ajax-loaded');
      }
    },

    url_hash_change(hash) {
      if (!hash) {
        Overlay.close_overlay();
        return false;
      }

      // Search for lightbox-inline links with THIS hash
      $('a[data-lightbox-inline=true]').each(function () {
        if ($.replace_square_brackets($(this).attr('href')) === $.replace_square_brackets(hash)) {
          $.trigger_overlay_iframe($(this).attr('href'));
          return false;
        }
      });

      // Search for lightbox-element links with THIS hash
      $('a[data-overlay-load-element]').each(function () {
        if ($(this).data('overlay-load-element') === `#${hash}`) {
          const url = $(this).data('overlay-load-element');
          $.trigger_overlay_dom_replacement(url);
          Overlay.history.push(url);
          return false;
        }
      });

      // Search for lightbox-ajax links with THIS hash
      $('a[data-lightbox-ajax=true]').each(function () {
        if ($.replace_square_brackets($(this).attr('href')) === $.replace_square_brackets(hash)) {
          const $this = $(this);
          const url = $this.attr('href');
          Overlay.destroy_content_on_close = $this.data('destroy-content-on-close') === true;
          $.trigger_overlay_ajax(url);
          Overlay.history.push(url);
          return false;
        }
      });

      // FALLBACK:
      // If we cannot find a link, load the ajax content straight in it
      if (hash.match(/^\/\w+/)) {
        // checks if hash starts with '/word-character'
        const url = hash;
        $.trigger_overlay_ajax(url);
        Overlay.history.push(url);
        return false;
      }
    },

    remove_hash_from_url() {
      let scrollV,
        scrollH,
        loc = window.location;
      if ('pushState' in history) {
        // Chrome 9, Firefox 4, Safari 5, Opera 11.50 and in IE 10
        history.pushState('', document.title, loc.pathname + loc.search);
      } else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;
        loc.hash = '';
        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
      }
    },

    replace_square_brackets(str) {
      if (typeof str !== 'string') return str;
      return str.replace(/\[/g, '%5B').replace(/\]/g, '%5D').replace(/:/g, '%3A');
    },
  });
};

extendJQuery();

documentReady(function () {
  extendJQuery();
  Overlay.container = $('#modal_overlay');

  Overlay.container.on('hidden.bs.modal', function () {
    Overlay.on_hidden();
  });

  Overlay.make_draggable();

  // system messages
  $.trigger_overlay_sys_msg();

  // iframe considerings
  Overlay.links_to_parent_window('.iframed');
  Overlay.resize_iframe_on_clicks();

  // handle URL changes (hash only)
  $(window).bind('hashchange', function () {
    const current_hash = window.location.hash.replace(/#/g, '');
    $.url_hash_change(current_hash);
  });

  // handle URL initial hashes
  if (window.location.hash !== '') {
    $.url_hash_change(window.location.hash.replace(/#/g, ''));
  }

  // Have clicking on <a> tags load the lightbox by changing the hash.
  // NOTE: This will cascade into a `hashchange` event above, resulting in the actual call.
  $(document).on(
    'click',
    'a[data-lightbox-ajax][href], a[data-lightbox-inline][href], a[data-overlay-load-element]', // WARNING: Multiple selectors here!
    event => {
      const target = event.currentTarget;

      const hash = target.getAttribute('data-overlay-load-element') || target.getAttribute('href');

      const skipLightbox = target.getAttribute('data-lightbox-ajax') === 'false';

      if (skipLightbox) return;

      if (!hash) return;

      event.stopPropagation();
      event.preventDefault();
      window.location.hash = hash;
    }
  );

  // Close overlay on ESC key press
  $(document).keyup(e => {
    // 27 == ESC key
    if (e.keyCode === 27) Overlay.close_overlay();
  });

  // iOS Safari select on modal bug.
  // See https://stackoverflow.com/questions/28320103/select-element-cause-a-scroll-to-top-of-the-page-on-ios-devices
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    $('.modal').on('show.bs.modal', function () {
      // Position modal absolute and bump it down to the scrollPosition
      $(this).css({
        position: 'absolute',
        marginTop: `${$(window).scrollTop()}px`,
        bottom: 'auto',
      });

      // Position backdrop absolute and make it span the entire page
      //
      // Also dirty, but we need to tap into the backdrop after Boostrap
      // positions it but before transitions finish.
      setTimeout(function () {
        $('.modal-backdrop').css({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: `${Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
          )}px`,
        });
      }, 0);
    });
  }
});

window.Overlay = Overlay;
export default Overlay;
