import { documentReady } from 'app/documentReady';

documentReady(function () {
  if ($('input#pro_consolidated_portfolio').length > 0) {
    let pro_consolidated_stored_results = undefined;
    const ajaxPoolConsolidated = new Array();
    $('input#pro_consolidated_portfolio').on('keypress', function (event) {
      $.each(ajaxPoolConsolidated, function () {
        return this.abort();
      });

      const keycode = event.keyCode ? event.keyCode : event.which;

      if (keycode === 13) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    });

    $('input#pro_consolidated_portfolio').on('focus', function () {
      if (pro_consolidated_stored_results) {
        const line_item = $('li.pro_consolidated_search_main_result_item')[0];
        if (line_item) {
          return $(line_item).parent().show();
        }
      }
    });

    return $('input#pro_consolidated_portfolio').on(
      'keydown.autocomplete',
      () =>
        ($('input#pro_consolidated_portfolio')
          .autocomplete({
            source(request, response) {
              const ajax_request = $.ajax({
                url: '/consolidated_portfolios/pro_search',
                dataType: 'json',
                data: {
                  search: request.term,
                },

                success(data) {
                  const items = $.map(data, el => ({
                    id: el['id'],
                    client_name: el['client_name'],
                    portfolio_name: el['portfolio_name'],
                    country: el['country'],
                  }));
                  response(items);
                  return (pro_consolidated_stored_results = items);
                },
              });
              return ajaxPoolConsolidated.push(ajax_request);
            },

            select(event, ui) {
              if (!ui.item.id) {
                return false;
              }
              if (
                $(`div#pro_consolidate_portfolio_list input#portfolio_${ui.item.id}`).length > 0
              ) {
                $(`div#pro_consolidate_portfolio_list input#portfolio_${ui.item.id}`).attr(
                  'checked',
                  'checked'
                );
              } else {
                let display_label = ui.item.portfolio_name;
                if (ui.item.client_name.length > 0) {
                  display_label += ` (<i>${ui.item.client_name}</i>)`;
                }
                $(`<input type='checkbox' name='consolidated_portfolio[portfolio_ids][]' value='${ui.item.id}' checked='checked' \
id='portfolio_${ui.item.id}' /> <label for='portfolio_${ui.item.id}'>${display_label}</label> \
<span class='sublabel'>${ui.item.country}</span><br/>`).appendTo(
                  'div#pro_consolidate_portfolio_list'
                );
              }
              return false;
            },

            selectFirst: true,
            minLength: 3,
          })
          .data('ui-autocomplete')._renderItem = function (ul, item) {
          let displayed_as = `<b>${item.portfolio_name}</b>`;
          if (item.client_name.length > 0) {
            displayed_as += ` <i> ${item.client_name}</i>`;
          }
          displayed_as += `<br/><i>(${item.country})</i>`;
          return $("<li class='pro_consolidated_search_main_result_item'></li>")
            .data('item.autocomplete', item)
            .append(`<a>${displayed_as}</a>`)
            .appendTo(ul);
        })
    );
  }
});
