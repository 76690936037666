import { documentReady } from './documentReady';

class NewCashAccountForm {
  constructor(form) {
    this.prefill_name = this.prefill_name.bind(this);
    this.form = form;
    this.input_cash_account_name = 'input#cash_account_name';
    this.dropdown_xero_bank_accounts = 'select#cash_account_api_identifier';
  }

  prefill_name(select) {
    const name = select.find(':selected').text();
    return $(this.input_cash_account_name).val(`Xero - ${name}`);
  }
}

documentReady(function () {
  if ($('#cash_account_new form').length) {
    const form = new NewCashAccountForm();
    return $(document).on('change', form.dropdown_xero_bank_accounts, function () {
      return form.prefill_name($(this));
    });
  }
});
