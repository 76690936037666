global.INVESTAPP_SETTINGS = INVESTAPP_SETTINGS; // Required to run @sharesight/react.

import '../app/jquery-shim';

import '../app/analytics'; // Inintialize Analytics on every pageload.
import '../app/unsupported_browser'; // A notice at the top of the page to tell people their browser is out-of-date.
import '../app/add-to-home-screen'; // Analytics for our Add to Home Screen functionality

import '../app/variables';
import '../app/utils';
import '../app/holdings';
import '../app/module_radio_content_switcher';
import '../app/module_js_content_switcher';
import '../app/payout';
import '../app/reports';
import '../app/exchange_rates';
import '../app/instrument_price_loader';
import '../app/xero';
import '../app/overlay';
import '../app/tables';
import '../app/membership_form';
import '../app/links';
import '../app/initialize_tabs';
import '../app/ad_hoc_instruments';
import '../app/generate_uuid';
import '../app/make_dynamic_form';
import '../app/datepicker';
import '../app/initialization_reminder';
import '../app/drag_and_drop';
import '../app/direct_debit';
import '../app/downgrade_plan';
import '../app/file_upload';
import '../app/ad_hoc_move_search';
import '../app/professional_search';
import '../app/charts';
import '../app/bulk_import';
import '../app/page_header_updates';
import '../app/ajax_forms';
import '../app/plans';
import '../app/non_editor';
import '../app/modal_confirm';
import '../app/instrument';
import '../app/instrument_autocompleter';
import '../app/cgt_report';
import '../app/locks';
import '../app/payment';
import '../app/payout_form';
import '../app/portfolio';
import '../app/holding_handlers';
import '../app/holding_merge_form';
import '../app/holding_comments';
import '../app/radio_btn';
import '../app/load_inline';
import '../app/login_signup';
import '../app/syncing_check';
import '../app/collapsible';
import '../app/invitation_links';
import '../app/cash_account_forms';
import '../app/criteria_bar_advanced_options';
import '../app/dollar_percent_switch';
import '../app/xignite_loading';
import '../app/recaptcha';
import '../app/admin_as_user';
import '../app/LinkLock';
import '../app/proResetPortfolio';
import '../app/charts_drawer';
import '../app/criteria_form_charting';
import '../app/auto_submit';
import '../branding/pro';
import '../app/payout_field_control';
import '../app/user_edit';
import '../app/sharesightconnect';
import '../app/signup_postal_code_input';
