// NOTE: This file is used by "ad_hoc/move"
import { documentReady } from './documentReady';

const expiredInstrumentPattern = new RegExp(/<?.* ?.*>(.*)<\/?.*>/);

const AdHocMoveSearch = {
  init: () => {
    const inputElement = $('input[data-ad-hoc-move-search=true]');
    if (!inputElement.length) return;

    let searchInstrumentAjaxPool = [];
    inputElement.on('keypress', () => {
      searchInstrumentAjaxPool.forEach(request => request.abort()); // abort previous $.ajax requests
    });

    const autoCompleteElement = inputElement.autocomplete({
      minLength: 1,
      source: (request, response) => {
        const ajaxRequest = $.ajax({
          url: '/instruments/find.js',
          dataType: 'json',
          data: {
            term: request.term,
            market_id: $('#holding_instrument_market_id').val(),
            portfolio_id: $('input[name=portfolio_id]').val(),
          },
          success: AdHocMoveSearch.onSearchSuccess(response),
        });

        searchInstrumentAjaxPool.push(ajaxRequest); // so we can abort them later
        return ajaxRequest;
      },
      focus: AdHocMoveSearch.onFocus,
      select: AdHocMoveSearch.onSelect,
    });

    autoCompleteElement.autocomplete('instance')._renderItem = (ul, item) =>
      $("<li class='main_result_item' data-ui-autocomplete-item></li>")
        .data('item.autocomplete', item)
        .append(`<a>${item.label}</a>`)
        .appendTo(ul);

    return autoCompleteElement;
  },

  onFocus: function (event, ui) {
    // requires `this` from onFocus event
    let itemCode = ui.item.code;
    if (expiredInstrumentPattern.test(itemCode))
      itemCode = expiredInstrumentPattern.exec(itemCode)[1];
    this.value = itemCode;
    return false;
  },

  onSelect: function (event, ui) {
    const instrumentId = $('#holding_instrument_id');
    if (instrumentId.length) instrumentId.val(ui.item.value);

    const instrumentName = $('#holding_instrument_name');
    if (instrumentName.length) instrumentName.html(ui.item.name);
    return false;
  },

  onSearchSuccess: callback => res => {
    const items = res.instruments.map(el =>
      Object.assign({}, el, {
        value: el.id,
        label: `<strong>${el['code']}</strong> ${el['name']}`,
      })
    );

    return callback(items);
  },
};

documentReady(() => {
  AdHocMoveSearch.init();
  $(document).on('overlay-ajax-loaded', () => AdHocMoveSearch.init()); // re-initialize when ajax content loads
});
