import { documentReady } from './documentReady';

/*
 * This file used primarily by React code as a deprecated means to arbitrarily injected HTML into the page.
 * When we refactor the Portfolio Overview to use code from the Performance Report and APIs, most of this can be removed.
 */
export const Portfolio = {
  xhr: null,
  simulation_delay: 200,
  main_needs_to_reload: false,

  // used when submitting new form data, by ajax and not ajax
  fadePortfolioContent(container) {
    container.animate({ opacity: 0.2 });
  },

  reloadMainData(selector, data, callback) {
    if (Portfolio.xhr && Portfolio.xhr.readyState != 4) {
      Portfolio.xhr.abort();
    }
    const container = $(selector);

    Portfolio.xhr = $.ajax({
      type: 'get',
      url: container.data('reload-url'),
      data: data,
      dataType: 'html',

      beforeSend() {
        return Portfolio.fadePortfolioContent(container);
      },

      success(response) {
        container.trigger('unload_summary'); // this causes React to unload the summary bar component

        container.html(response);

        container.animate({ opacity: 1.0 });
        $('#content').trigger('main_reloaded'); // this causes React to re-render in `app/views/shared/_mount_javascript.html.erb`

        new SetupSorting(); // sorting for portfolio show when loaded by ajax

        if (typeof callback === 'function') callback();
      },

      error() {
        container.find('#js-reload-error').show();

        if (typeof callback === 'function') callback();
      },
    });
    return Portfolio.xhr;
  },
};

export var PortfolioLock = {
  alwaysWarn: false,

  // data: null,
  parseDate(date) {
    return $.datepicker.parseDate('dd/mm/yy', date);
  },

  lockedDate(date) {
    return (
      date.length > 0 &&
      PortfolioLock.parseDate(PortfolioLock.lockForm.data('locked-until')) >=
        PortfolioLock.parseDate(date)
    );
  },

  lockFound(field) {
    let dateIsLocked = false;
    if (PortfolioLock.lockForm.data('locked-check-if') !== undefined) {
      if ($(`#${PortfolioLock.lockForm.data('locked-check-if')}`)[0].checked) {
        dateIsLocked = PortfolioLock.lockedDate($(field).val());
      }
    } else {
      dateIsLocked = PortfolioLock.lockedDate($(field).val());
    }
    return dateIsLocked;
  },

  initLockedForm(form) {
    this.lockForm = form;
    const lockedFields = this.lockForm.find('input.lock-date-field');
    lockedFields.each(function () {
      if (PortfolioLock.lockFound(this)) return (PortfolioLock.alwaysWarn = true);
    });

    if (form.attr('id') !== 'js-bulk-import-form') {
      return PortfolioLock.lockForm.find('input[type=submit]').click(e =>
        lockedFields.each(function () {
          if (PortfolioLock.alwaysWarn || PortfolioLock.lockFound(this)) {
            e.preventDefault();
            return $('#js-dialog-lock-warning').modal('show');
          }
        })
      );
    }
  },
};

window._shares_embed_portfolio = Portfolio; // required for embed portfolio overview function in a separate site / html page
window.PortfolioLock = PortfolioLock;
window.Portfolio = Portfolio;

class SetupSorting {
  constructor() {
    if (!$("span[data-setup-sorting='true']").length) return;
    window.Sortable.setup_sorting();
    window.Sortable.reinit_current_th_click();
  }
}

documentReady(function () {
  // auto adjust to previews element width.
  const truncate = $('.truncate');
  const parent_width = $('.truncate').parent().width();
  truncate.css('max-width', parent_width);

  if ($('form[data-locked-until]').length !== 0) {
    $('#js-dialog-lock-warning-submit').click(function () {
      $('#js-dialog-lock-warning').modal('hide');
      if (
        $(this).closest("form[data-dialog-submit='true']").attr('data-dialog-submit') === 'true'
      ) {
        return $(this).closest("form[data-dialog-submit='true']").submit();
      }
    });

    PortfolioLock.initLockedForm($('form[data-locked-until]'));
  }

  $('#portfolio-locks').hide();

  // adjust trade buys/sells cash / portfolio settings tab (basic details)
  if ($('input#portfolio_cash_account_adjustment_option').length) {
    // only if you have cash accounts
    if (!$('input#portfolio_cash_account_adjustment_option').is(':checked')) {
      // hide settlement days input fields if option is unchecked
      $('*[data-cash-account-adjustment-option="true"]').hide();
    }
    $('input#portfolio_cash_account_adjustment_option').click(function () {
      // on click of the checkbox
      $('*[data-cash-account-adjustment-option="true"]').toggle();

      if ($(this).is(':checked')) {
        // prefill with default 3 days the input fields the first time the user turn the setting on
        if ($('input#portfolio_cash_account_adjustment_buys').val() === '') {
          $('input#portfolio_cash_account_adjustment_buys').val(3);
        }
        if ($('input#portfolio_cash_account_adjustment_sells').val() === '') {
          $('input#portfolio_cash_account_adjustment_sells').val(3);
        }
      }
    });
  }

  // show/hide settings as required by selected country
  $('select#new-portfolio-country-select').change(function () {
    const select_element = document.getElementById('new-portfolio-country-select');
    const country_id = select_element.value;
    const year_end_id = select_element.options[select_element.selectedIndex].dataset.yearEndId;
    document.getElementById('portfolio_financial_year_end_month_id').value = year_end_id;

    [...$('[data-country-id]')].forEach(countryControl => {
      countryControl.style.display =
        countryControl.dataset.countryId === country_id ? 'block' : 'none';
    });
  });
});
