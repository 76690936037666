import { documentReady } from './documentReady';

documentReady(function () {
  $('#user_edit_form.top_level').submit(function (event) {
    // Top level form submitted
    var was_password_disable = $('#was_password_disable').val() == '1';
    var is_password_disable = $('#password_disable').prop('checked');

    if (was_password_disable && !is_password_disable) {
      // Password re-enabled

      window.Overlay.container.on('overlay-ajax-loaded', function () {
        // Password entry overlay has loaded

        $('#user_edit_form.password_changer').submit(function () {
          // Submit in password entry form

          $('#user_edit_form.top_level input[type="submit"]').disabled = false;
          $('#was_password_disable').val('0');
          $('#user_edit_form.top_level').submit();
        });
      });

      window.Overlay.container.on('overlay-closed', function () {
        // Password entry overlay has been closed (by cancel)

        $('#password_disable').prop('checked', true);

        var top_level_submit = $('#user_edit_form.top_level input[type="submit"]');
        top_level_submit.prop('disabled', false);
        top_level_submit.val('Save my details');
      });

      $.trigger_overlay_ajax('/user/change_password?reason=enable');
      event.preventDefault();
    }
  });
});
