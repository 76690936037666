import { documentReady } from './documentReady';

documentReady(() => {
  if (!$('div#js-lock-form').data('need-check-trades-xero')) return;
  $('#js-lock-button').hide();

  $.get($('div#js-lock-form').data('need-check-trades-xero'), res => {
    $('div#js-lock-form').append(res);
    $('img#xero-trades-resync-lock').hide();
    $('#js-lock-button').show();
  });
});
