import moment from '@sharesight/react/dist/helpers/moment';

import { documentReady } from './documentReady';
import { initDatepickerSingle, formatInputDate } from './datepicker';

const Prices = () => {
  const add_row_click = function () {
    // add new price link click
    const $table = $('#instrument_price_table');
    const $tableBody = $('tbody', $table);
    const row_id = `ad_hoc_instrument_price_${new Date().getMilliseconds()}`;
    const newRow = $('#hidden_new_price', $tableBody)
      .clone()
      .appendTo($tableBody)
      .attr('class', 'instrument_price_table')
      .attr('id', row_id)
      .show();
    newRow.find('tr').slideDown(300);

    // NOTE: Here we just reset the new date to today – the old date has already passed through datepicker and could be either m/d/y or d/m/y...
    newRow.find('.last_traded_on').val(moment().format('YYYY-MM-DD'));

    window.Reports.preparePriceRow(row_id);
    return false; // kill the browser default action
  };

  const destroy_price = function ($elem) {
    // click destroy price link
    const clicked_row = $elem.parent().parent('tr');
    const instr_price_id = $elem.attr('id');
    $elem.attr('disabled', true);
    const url = $('a[data-ad-hoc-instrument-prices-url]').data('ad-hoc-instrument-prices-url');
    $.ajax({
      url: `${url}/${instr_price_id}`,
      type: 'post',
      data: '_method=delete',
      success(/* data */) {
        clicked_row.fadeOut(300, function () {
          clicked_row.replaceWith('');
        });
        return false;
      },
      error(request, text_status, error_thrown) {
        alert(`${text_status} ${error_thrown}`);
      },
    });
    return false;
  };

  const set_editable_fields = function () {
    // make price editable inline
    const url = $('a[data-ad-hoc-instrument-prices-add-url]').data(
      'ad-hoc-instrument-prices-add-url'
    );
    // this hits "/holdings/.../instrument_prices/new/in_line_update"

    $('.date_edit')
      .editable(url, {
        method: 'POST',
        name: 'last_traded_on',
        cancel: 'Cancel',
        submit: 'Save',
        style: 'inherit',
        onblur: 'ignore',
        type: 'datepicker',
        width: '100px',
        onerror(s, o, x) {
          alert(
            `Sorry, we could not save the record due to the follow errors\n${x.responseText}\nPlease correct or cancel`
          );
        },
      })
      .on('DOMNodeInserted', function () {
        // trim spaces
        const $input = $(this).find(`input[name='last_traded_on']`);
        if (!$input.length) return;
        $input.val(($input.val() || '').trim());
      });

    $('.ex_rate_edit')
      .editable(url, {
        method: 'POST',
        name: 'last_traded_value',
        cancel: 'Cancel',
        submit: 'Save',
        style: 'inherit',
        onblur: 'ignore',
        width: '70px',
      })
      .on('DOMNodeInserted', function () {
        // trim spaces
        const $input = $(this).find(`input[name='last_traded_value']`);
        if (!$input.length) return;
        $input.val(($input.val() || '').trim());
      });
  };

  // ad hoc holding - submit price form
  if ($('a#enter_a_price').length) {
    $('a#enter_a_price').click(() => $('#enter_a_price_wrapper').slideToggle());

    $(document).on('submit', '#submit_price_form', function (/* event */) {
      $.ajax({
        data: $('#submit_price_form').serialize(),
        type: 'post',
        success(data) {
          // NOTE: Can't really cache here because of the `html()`, but it's not worth refactoring to get rid of that bad code.
          $('#ad_hoc_instrument_price').html(data);
          const datepicker = $(
            '#ad_hoc_instrument_price input[name="instrument_price[last_traded_on]"]'
          );

          // since we've blindly overriden the html with data, let's re-initialize the datepicker
          if (datepicker.length) {
            formatInputDate(datepicker);
            initDatepickerSingle.call(datepicker);
          }

          setTimeout(function () {
            $('#new_instrument_price_message').slideUp();
          }, 1500);

          return false;
        },
        error(request, text_status, error_thrown) {
          alert(`${text_status} ${error_thrown}`);
        },
        url: $(this).attr('action'),
      });
      return false;
    });
  }

  if (!$('a[data-ad-hoc-instrument-prices-add-url]').length) return;
  $('#hidden_new_price').hide();
  set_editable_fields();
  $(document).on('ad-hoc-price-added', () => set_editable_fields());
  $('#add_row').click(() => add_row_click());

  $(document).on('click', 'a.destroy', function () {
    return destroy_price($(this));
  });
};

const InstrumentPriceTrIds = () => {
  if (!$("table#instrument_price_table tr[data-instrument-price-tr='true']").length) return;
  $("table#instrument_price_table tr[data-instrument-price-tr='true']").each(function () {
    return window.Reports.preparePriceRow($(this).attr('id'));
  });
};

const InstrumentEdit = () => {
  if (!$('input#form_instrument_name_submit').length) return;
  $(document).on('click', '.js-editable-instrument-name .js-edit-link', function () {
    $(this).closest('.js-editable-instrument-name').hide();
    $('form#update_instrument_form').show();
  });

  $('form#update_instrument_form')
    .bind('ajax:before', function () {
      $(this).css('opacity', 0.6);
      $('input#form_instrument_name_submit').hide();
    })
    .bind('ajax:complete', function (data, status /*, xhr */) {
      $(this).hide();
      $(this).css('opacity', 1);
      $('input#form_instrument_name_submit').show();
      $('.js-editable-instrument-name').html(status.responseText);
      $('.js-editable-instrument-name').show();
    });
};

documentReady(function () {
  Prices(); // separate page
  InstrumentEdit(); // edit name from holding page
  return InstrumentPriceTrIds();
});
