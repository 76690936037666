import Spinner from 'images/spinner_16x16.gif';
import { documentReady } from './documentReady';

const LoadInline = {
  hook: '',
  url: '',
  txt: '',
  spinner: '',

  initLoadInline() {
    this.hook = $('*[data-load-inline]');
    this.url = this.hook.data('load-inline');
    this.txt = this.hook.data('load-inline-loading');
    this.spinner = this.create_spinner(this.txt);
    this.hook.hide();
    return this.load_content();
  },

  create_spinner(txt) {
    let spinner_txt;
    const spinner_box = $('<span />').addClass('spinner').append();
    const spinner_gif = $('<span>&nbsp;</span>');
    if (txt) {
      spinner_txt = $(`
        <small>
          <img class="spinner" src="${Spinner}" />${txt}
        </small>
      `);
    }
    return (this.spinner = spinner_box.append(spinner_gif).append(spinner_txt));
  },

  load_content() {
    this.hook.before(this.spinner);
    if (!this.hook.length) return;

    return this.hook.load(this.url, function (response, status) {
      if (status === 'success') {
        LoadInline.spinner.fadeOut(200, () =>
          LoadInline.hook.slideDown(function () {
            // resize iframe, unless it is no iframe
            if (self !== top) return window.parent.Overlay.auto_resize_parent_iframe();
          })
        );
      }
    });
  },
};

window.LoadInline = LoadInline;

documentReady(() => {
  LoadInline.initLoadInline();
});
