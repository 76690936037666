import { documentReady } from './documentReady';

documentReady(() => {
  if (!$("span[data-direct-debit-form-bind='true']").length) return;

  $(document).on(
    'keyup',
    'input#bank_number1, input#bank_number2, input#bank_number3, input#bank_number4',
    function () {
      if ($(this).val().length === parseInt($(this).attr('maxlength'))) {
        return $(this).next().focus();
      }
    }
  );
});
